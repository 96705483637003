/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./newsletter-erfolg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./newsletter-erfolg.component";
import * as i5 from "@angular/common/http";
import * as i6 from "../../../services/main.service";
var styles_NewsletterErfolgComponent = [i0.styles];
var RenderType_NewsletterErfolgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewsletterErfolgComponent, data: {} });
export { RenderType_NewsletterErfolgComponent as RenderType_NewsletterErfolgComponent };
function View_NewsletterErfolgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header-background-img header-wrapper headerBild"]], [[4, "backgroundImage", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "static-page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Newsletter"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.headerbild_MP) + ")"); _ck(_v, 0, 0, currVal_0); }); }
function View_NewsletterErfolgComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header-background-img header-wrapper headerBild"]], [[4, "backgroundImage", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "static-page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Newsletter"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.headerbild_PB) + ")"); _ck(_v, 0, 0, currVal_0); }); }
export function View_NewsletterErfolgComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "static-page-layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsletterErfolgComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsletterErfolgComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "center-content-narrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h2", [["class", "mp-font-medium-yellow text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vielen Dank f\u00FCr deine Anmeldung!"])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "p", [["class", "text-center spacing font-wight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Du hast dich erfolgreich angemeldet. "])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["routerLink", "/willkommen/step-1"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "strong", [["class", "font-italic"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Zur Startseite"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.storeData == null) ? null : _co.storeData.master) === 1); _ck(_v, 5, 0, currVal_0); var currVal_1 = (((_co.storeData == null) ? null : _co.storeData.master) === 3); _ck(_v, 7, 0, currVal_1); var currVal_4 = "/willkommen/step-1"; _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 15).target; var currVal_3 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_2, currVal_3); }); }
export function View_NewsletterErfolgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-newsletter-erfolg", [], null, null, null, View_NewsletterErfolgComponent_0, RenderType_NewsletterErfolgComponent)), i1.ɵdid(1, 114688, null, 0, i4.NewsletterErfolgComponent, [i5.HttpClient, i6.MainService, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsletterErfolgComponentNgFactory = i1.ɵccf("app-newsletter-erfolg", i4.NewsletterErfolgComponent, View_NewsletterErfolgComponent_Host_0, {}, {}, []);
export { NewsletterErfolgComponentNgFactory as NewsletterErfolgComponentNgFactory };
