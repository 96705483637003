<!-- <div class="to-top-button show-mobile" (click)="toTop()" *ngIf="showUpButton"> -->




<ul class="nav sidebar-nav" *ngIf="show_navigation">
    <!--   <div class="to-down-button " (click)="toBottom()" *ngIf="showDownButton">
           <button class="mp-btn-down"></button>
       </div>
       <div class="to-up-button " (click)="toTop()" *ngIf="showUpDownButton">
           <button class="mp-btn-up"></button>
       </div>-->

    <li class="nav-item logo-mobile-menu mb-4 mb-xl-0" [class.menu-active]="current_page_id == -1"
        (click)="onToggleOpen(-1)">
        <a routerLink="/shop">
            <svg-icon src="{{logo}}" [svgStyle]="{ 'width.px':150,'fill':'rgb(249,186,0)' }"
                      class="logo-mobil"></svg-icon>
            <svg-icon src="{{logo}}" [svgStyle]="{ 'width.px':200,'fill':'rgb(249,186,0)' }"
                      class="logo-desk"></svg-icon>
        </a>
    </li>
    <li class="cart-menu mt-5 mb-4">
        <button class="mp-btn mp-btn-cart" routerLink="/shop/bestellen/warenkorb-index">
            <svg-icon src="assets/icons/cart.svg"
                      [svgStyle]="{ 'width.px':40, 'height.px': 33, 'margin-right.px': 10 }"></svg-icon>
            Warenkorb
            <span class="cart-count ml-2">{{total_amount}}
            </span>
        </button>
    </li>
    <li *ngIf="showOffer" class="nav-item icon-aktion" [class.menu-active]="current_page_id == 0"
        (click)="onToggleOpen(0)">
        <a class="nav-link" routerLink="/shop/bestellen/aktion">
            <svg-icon src="assets/icons/icon_aktion.svg" [svgStyle]="{ 'width.px':35, 'height.px': 35 }"></svg-icon>
            <span>Aktion</span>
        </a>
    </li>
    <li *ngIf="showLunch" class="nav-item icon-lunch" [class.menu-active]="current_page_id == 10"
        (click)="onToggleOpen(10)">
        <a class="nav-link" routerLink="/shop/mittags-angebot">
            <svg-icon src="assets/icons/icon_clock.svg" [svgStyle]="{ 'width.px':35, 'height.px': 35 }"></svg-icon>
            <span>Mittagstisch</span>
        </a>
    </li>
    <ng-container *ngFor="let masterCategory of masterCategories">
        <li *ngIf="masterCategory.show_in_navigation" class="nav-item menu-icon"
            [class.menu-active]="current_page_id == masterCategory.id"
            (click)="onToggleOpen(masterCategory.id)">
            <a class="nav-link" routerLink="/shop/bestellen/{{masterCategory.id}}/{{masterCategory.master_cat_name}}">
                <span class="svg-icon" [innerHTML]="masterCategory.icon2|safeHtml"></span>
                <span>{{masterCategory.master_cat_name}}</span>
            </a>
        </li>
    </ng-container>
    <li class="nav-item icon-lunch" [class.menu-active]="current_page_id == 10"
        (click)="onToggleOpen(10)">
        <a class="nav-link" routerLink="/shop/bestellen/coupons">
            <svg-icon src="assets/icons/Icon_Sparcoupon_weiss.svg" [svgStyle]="{ 'width.px':35, 'height.px': 35 }"></svg-icon>
            <span>Coupons</span>

        </a>
    </li>
</ul>

<div *ngIf="!show_navigation">
    <ul class="nav sidebar-nav">
        <li class="nav-item logo-mobile-menu" [class.menu-active-0]="current_page_id == -1" (click)="onToggleOpen(-1)">
            <a routerLink="/shop">
                <svg-icon src="{{logo}}" [svgStyle]="{ 'width.px':150,'fill':'rgb(249,186,0)' }"
                          class="logo-mobil"></svg-icon>
                <svg-icon src="{{logo}}" [svgStyle]="{ 'width.px':200,'fill':'rgb(249,186,0)' }"
                          class="logo-desk"></svg-icon>
            </a>
        </li>
       <!-- <li class="nav-item mt-7" style="margin-top:3rem ">-->
        <li class="nav-item mt-7" >
            <button class="btn-mp btn-yellow btn-block" routerLink="/">Jetzt bestellen</button>
        </li>
    </ul>
</div>


