/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i4 from "angular-svg-icon";
import * as i5 from "@angular/common";
import * as i6 from "./header.component";
import * as i7 from "../../../services/cart.service";
import * as i8 from "../../../services/auth.service";
import * as i9 from "../../../services/user.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "menu-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "menu-user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "button", [["class", "btn-cart"], ["routerLink", "/shop/bestellen/warenkorb"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "svg-icon", [["src", "assets/icons/cart.svg"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(5, 1032192, null, 0, i4.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i4.SvgIconRegistryService], { src: [0, "src"], svgStyle: [1, "svgStyle"] }, null), i1.ɵpod(6, { "width.px": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "cart-count-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "/shop/bestellen/warenkorb"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "assets/icons/cart.svg"; var currVal_2 = _ck(_v, 6, 0, 34); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.total_amount; _ck(_v, 8, 0, currVal_3); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "header"], ["id", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "menu-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "hamburger-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "hamburger animated fadeInLeft is-closed"], ["data-toggle", "offcanvas"], ["type", "button"]], [[2, "is-open", null], [2, "is-closed", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "hamb-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "hamb-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "hamb-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "menu-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "a", [["routerLink", "/shop"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "text-uppercase mp-font-large-yellow"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(15, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "/shop"; _ck(_v, 11, 0, currVal_4); var currVal_6 = _co.show_cart; _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.is_open; var currVal_1 = !_co.is_open; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 11).target; var currVal_3 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_5 = _co.storeData.name; _ck(_v, 13, 0, currVal_5); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i6.HeaderComponent, [i7.CartService, i8.AuthService, i9.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i6.HeaderComponent, View_HeaderComponent_Host_0, { is_open: "is_open" }, { toggle_is_open_emitter: "toggle_is_open_emitter" }, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
