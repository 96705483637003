<div class="header" id="header">
    <div class="menu">
      <div class="menu-left">
          <div class="hamburger-menu">
            <button type="button" class="hamburger animated fadeInLeft is-closed" [class.is-open]="is_open"
              [class.is-closed]="!is_open" (click)="onToggle()" data-toggle="offcanvas">
              <span class="hamb-top"></span>
              <span class="hamb-middle"></span>
              <span class="hamb-bottom"></span>
            </button>
          </div>
      </div>

      <div class="menu-center">
        <div class="logo">
            <a routerLink="/shop">
                <!--<span class="text-uppercase mp-font-large-yellow">Meisterpizza</span>-->
                <span class="text-uppercase mp-font-large-yellow">{{ storeData.name }}</span>
            </a>
            </div>
      </div>

      <div class="menu-right" *ngIf="show_cart">
          <div class="menu-user">
            <button class="btn-cart" routerLink="/shop/bestellen/warenkorb">
              <svg-icon src="assets/icons/cart.svg" [svgStyle]="{ 'width.px': 34 }"></svg-icon>
              <span class="cart-count-mobile">{{total_amount}}</span>
            </button>
          </div>
      </div>

    </div>

   <!-- <hr class="menu-trenner">-->
  </div>
