import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ueberuns',
  templateUrl: './ueberuns.component.html',
  styleUrls: ['./ueberuns.component.scss']
})
export class UeberunsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
