<div class="static-page-layout">
    <ng-container>

        <div class="row">

            <!--Header-->
            <div class="col-md-12">
                <div class="header-background-img header-wrapper"
                     style="background-image: url('assets/img/header/Headerbilder_Produktgruppen_2184x656px_Spar-Coupon.jpg')">
                    <h1 class="static-page-title">Spar-Coupon</h1>
                </div>
            </div>

            <!--Content-->
            <div class="col-md-12">
                <div class="center-content-narrow">

                    <h2 class="text-center mp-font-medium-yellow">Spar-Coupon</h2>

                    <!--Content Meisterpizza-->
                    <div *ngIf="storeData?.master === 1"  class="contentCoupon">
                        <h5 class="text-center">Willkommen in unserem Stammkundenbereich!</h5>
                        <p class="text-left spacing">
                            Bist du bei uns für Newsletter angemeldet oder bekommst du von uns die Werbung per Post,
                            erhältst du dabei unsere Sparcoupons.
                        </p>
                        <h5 class="text-center">Wie es funktioniert?</h5>
                        <p class="text-left spacing">
                            Lege deine Lieblingspizza, Burger, Salat oder Fingerfood zum Mindestbestellwert in den
                            Warenkorb
                            und gebe den Sparcoupon-code bei der Bestellung ein wie es hier angezeigt:
                        </p>

                        <div class="text-center containerBild">
                            <img class="img-fluid rounded d-block " alt="Bestelldetails"
                                 src='assets/img/dummy/bestelldetailsMP.png'>
                        </div>

                        <p class="text-left spacing">
                            Der Artikel aus dem Sparcoupon erscheint automatisch im Warenkorb und
                            wird dir zusammen mit deiner Bestellung zugeliefert oder zur Abholung vorbereitet.
                        </p>

                        <!--  <p class="text-center spacing mp-font-medium-yellow">
                              Danke, dass du bei uns bleibst!
                          </p>-->
                    </div>

                    <!--Content Pizza Blitz-->
                    <div *ngIf="storeData?.master === 3"  class="contentCoupon">
                        <!--  <h5 class="text-center">Spar-Coupons</h5>-->
                        <p class="text-left">
                            Spar-Coupons oder Gutscheine kannst du bei uns auf viele verschiedene Weisen erhalten, z.B. per Newsletter, per Postwurfsendung, an deiner Rechnung usw., einfach die Augen aufhalten und kräftig sparen.
                        </p>
                        <h5 class="text-center">Wie funktioniert die Eingabe?</h5>
                        <p class="text-left spacing">
                            Einfach auf das Warenkorbsymbol im Shop klicken und unter Spar-Coupons / Gutscheine deinen Code eingeben und einlösen. So kannst du kräftig sparen!
                        </p>

                        <div class="text-center containerBild">
                            <img  class="img-fluid rounded d-block " alt="Bestelldetails"
                                  src='assets/img/dummy/Sparcoupons_PB.png'>
                        </div>

                        <p class="text-left spacing">
                            Der Artikel aus dem Sparcoupon erscheint automatisch im Warenkorb und
                            wird dir zusammen mit deiner Bestellung zugeliefert oder zur Abholung vorbereitet.
                        </p>

                        <!-- <p class="text-center spacing mp-font-medium-yellow">
                             Danke, dass du bei uns bleibst!
                         </p>-->
                    </div>


                </div>
            </div>
        </div>

    </ng-container>
</div>
