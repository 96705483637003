<div class="row mb-4 pb-4" *ngIf="ready">
    <div class="col-12 d-flex justify-content-center align-items-center">
        <ng-container *ngIf="data.image_desktop.webp">
            <picture>
                <source media="(min-width: 120.5em)" srcset="{{image_url}}{{data.image_desktop.webp[0].image}}" type="image/webp">
                <source media="(min-width: 120.5em)" srcset="{{image_url}}{{data.image_desktop.jpg[0].image}}">
                <source media="(min-width: 80em)" srcset="{{image_url}}{{data.image_desktop.webp[1].image}}" type="image/webp">
                <source media="(min-width: 80em)" srcset="{{image_url}}{{data.image_desktop.jpg[1].image}}">
                <source media="(min-width: 60em)" srcset="{{image_url}}{{data.image_desktop.webp[2].image}}" type="image/webp">
                <source media="(min-width: 60em)" srcset="{{image_url}}{{data.image_desktop.jpg[2].image}}">
                <source media="(min-width: 48em)" srcset="{{image_url}}{{data.image_desktop.webp[3].image}}" type="image/webp">
                <source media="(min-width: 48em)" srcset="{{image_url}}{{data.image_desktop.jpg[3].image}}">
                <source media="(min-width: 36em)" srcset="{{image_url}}{{data.image_mobile.webp[4].image}}" type="image/webp">
                <source media="(min-width: 36em)" srcset="{{image_url}}{{data.image_mobile.jpg[4].image}}">
                <source media="(min-width: 30em)" srcset="{{image_url}}{{data.image_mobile.webp[5].image}}" type="image/webp">
                <source media="(min-width: 30em)" srcset="{{image_url}}{{data.image_mobile.jpg[5].image}}">
                <source media="(min-width: 22.5em)" srcset="{{image_url}}{{data.image_mobile.webp[6].image}}" type="image/webp">
                <source media="(min-width: 22.5em)" srcset="{{image_url}}{{data.image_mobile.jpg[6].image}}">
                <source srcset="{{image_url}}{{data.image_mobile.webp[7].image}}" type="image/webp">
                <source srcset="{{image_url}}{{data.image_mobile.jpg[7].image}}">
                <img src="{{image_url}}{{data.image_mobile.jpg[1].image}}" alt="" class="img-fluid">
            </picture>
        </ng-container>
    </div>
</div>
