import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MainService} from '../../../services/main.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-coupon',
    templateUrl: './coupon.component.html',
    styleUrls: ['./coupon.component.scss']
})

export class CouponComponent implements OnInit {
    storeData: any;

    constructor(
        private http: HttpClient,
        private main_service: MainService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.storeData = JSON.parse(localStorage.getItem('storedata'));
    }
}
