import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-mittags-angebot',
  templateUrl: './mittags-angebot.component.html',
  styleUrls: ['./mittags-angebot.component.scss']
})
export class MittagsAngebotComponent implements OnInit {
  storeData: any;

  // headerBilder
  headerbild_Mittags_MP = "/assets/img/header/Meisterpizza-Headerbild_Obergruppen_304x170px_Mittagsangebot_k.jpg";
  headerbild_Mittags_MP_Desktop = "/assets/img/header/Meisterpizza_Headerbild_Obergruppen_2184x656px_Mittags-Angebot_k.jpg";

  headerbild_Mittags_MD = "/assets/img/header/Meisterpizza-Headerbild_Obergruppen_304x170px_Mittagsangebot_k.jpg";
  headerbild_Mittags_MD_Desktop = "/assets/img/header/Meisterpizza_Headerbild_Obergruppen_2184x656px_Mittags-Angebot_k.jpg";

/*  headerbild_Mittags_PB = "/assets/img/header/PizzaBlitz_Headerbild_Obergruppen_304x170px_Mittagsangebot.jpg";*/
  headerbild_Mittags_PB = "/assets/img/mittagsangebote/PizzaBlitz_Headerbilder_Obergruppen_304x170px_Mittagsangebot_9_50.jpg";
  headerbild_Mittags_PB_Desktop = "/assets/img/mittagsangebote/PizzaBlitz_Headerbilder_Obergruppen_2184x656px_Mittagsangebot_9_50.jpg";

  sizeSmall = true;


  constructor() { }

  ngOnInit() {
    this.storeData = JSON.parse(localStorage.getItem('storedata'));
    window.onresize = this.setHeaderbild;
    this.setHeaderbild();

  }

  // Headerbild setzen
  setHeaderbild() {
    let small = window.matchMedia('(max-width: 576px)').matches; //false oder true
    if (small == this.sizeSmall) return;
    this.sizeSmall = small;
    /*console.log("Kleines Bild = ",small);*/
    if (small === false) {
      this.headerbild_Mittags_MP = this.headerbild_Mittags_MP_Desktop;
      this.headerbild_Mittags_PB = this.headerbild_Mittags_PB_Desktop;
      this.headerbild_Mittags_MD = this.headerbild_Mittags_MD_Desktop;
      // console.log(this.headerbild_Mittags_PB, "MP-DESKTOP");
      // console.log(this.headerbild_Mittags_MP, "PB-DESKTOP");
    }
    else {
      // console.log(this.headerbild_Mittags_MP, "MP-MOBIL");
      // console.log(this.headerbild_Mittags_PB, "PB-MOBIL");
    }
  }

getHeaderBild() {
      var tmpUrl;
      if (this.storeData.id== 2){
          tmpUrl= this.headerbild_Mittags_MP;
      } else if(this.storeData.id== 7) {
          tmpUrl= this.headerbild_Mittags_MD;
      } else if(this.storeData.master== 3) {
          tmpUrl= this.headerbild_Mittags_PB;
      }

      return  tmpUrl;
}

}
