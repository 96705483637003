<div class="static-page-layout">

  <div class="row">
    <div class="col-md-12">
      <div class="header-background-img header-wrapper"
        style="background-image: url('assets/img/header/header-about.png')">
        <h1 class="static-page-title">Über uns</h1>
      </div>
    </div>
  </div>

  <div class="row">
      <strong class="text-margin">Wir stehen für ehrliche und nachhaltige Produkte. Von uns dürfen Sie immer etwas mehr erwarten…</strong>
  </div>

  <div class="row">
    <div class="box box-blue col-xl-3 col-lg-6 col-12">
      <div class="box-inner-wrap">
        <strong>in unseren</strong>
        <h2 class="big-yanone text-white">Erfahrungen</h2>
        <p>
            die wir in mehr als 20 Jahren in der Home Delivery-Branche leidenschaftlich gesammelt haben. Unsere Erfahrung lassen wir stetig in die Unternehmensführung mit einfließen. Sei es in der Kreation neuer Produkte oder im Bezug auf den gesamten Bestellprozess, um Bestellung und Lieferung für unsere Kunden noch einfacher und schneller zu machen.
        </p>
      </div>
    </div>

    <div class="box box-red col-xl-3 col-lg-6 col-12">
      <div class="box-inner-wrap">
        <strong>in der</strong>
        <h2 class="big-yanone text-white">Qualität</h2>
        <p>
            sind wir absolute Spitze. Und damit das so bleibt, achten wir beim Einkauf sehr genau auf die Herkunft, Verarbeitung und Nachhaltigkeit unserer Zutaten. Wir stehen unseren Lebensmitteln mit großem Respekt gegenüber, denn Qualität hat ihren Geschmack!
        </p>
      </div>
    </div>

    <div class="box box-yellow col-xl-3 col-lg-6 col-12">
      <div class="box-inner-wrap">
        <strong>im</strong>
        <h2 class="big-yanone text-white">Preis-Leistungs-Verhältnis</h2>
        <p>
            ist uns Geiz zu billig. Durch unser ausgewogenes Preis-Leistungs-Verhältnis sind wir in der guten Lage, unsere Produkte reichlich zu belegen und diese in anspruchsvollen Verpackungen an unsere Kunden weiterzugeben.
        </p>
      </div>
    </div>

    <div class="box box-grey col-xl-3 col-lg-6 col-12">
      <div class="box-inner-wrap">
        <strong>in der</strong>
        <h2 class="big-yanone text-white">Hygiene</h2>
        <p>
            bei Meisterpizza stehen wir in der Pflicht, unseren hohen Anforderungen dafür täglich gerecht zu werden. Um eine professionelle Grundlage dafür zu schaffen, verwenden wir gelistete Reinigungsmittel. Auch im Umgang mit den Zutaten und Produkte, kann Hygiene in unserem Meisterpizza Store nicht groß genug geschrieben werden. Ob es das Arbeiten mit speziellen Hygiene-Handschuhen oder die optimale Kühlung der Zutaten ist, Sauberkeit spielt für uns eine gewichtige Rolle im täglichen Umgang mit Lebensmitteln. Gute Hygiene kostet, die ist es uns aber Wert!
        </p>
      </div>
    </div>

  </div>

</div>
