import {Injectable} from '@angular/core';
import {FrontendService} from './frontend.service';
import {Router} from '@angular/router';
import {CartService} from './cart.service';
import {Observable} from 'rxjs';
import {catchError, retry, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MainService} from './main.service';



@Injectable()

export class DateService {

   private date: any = false;
   private diff: number = 0;

    constructor(
        private http: HttpClient,
        private main_service: MainService
    ) {


    }


    load() : Promise <any> {

        this.InitInterval();
        return this.getServerDateTime();


    }

    getServerDateTime(){
        return this.http.get(this.main_service.getFrontendUrl() + '/DateTime')
            .toPromise()
            .then((data: any) => {

                var response = data.DateTime + '';
                var vers = data.version;
                if (vers) {
                    this.checkVersion(vers);
                }
                response = response.substring(0, 19);
                const servertime = new Date(response);
                const localtime = new Date();
                this.diff = servertime.getTime() - localtime.getTime();
            });
    }

    checkVersion(vers) {
        var appVersion = vers + '';
        if(localStorage.getItem('appVersion') && localStorage.getItem('appVersion') != '') {
            if (localStorage.getItem('appVersion') != appVersion) {
                localStorage.setItem('appVersion', appVersion);
                document.location.reload();
            }
        } else {
            localStorage.setItem('appVersion', appVersion);
        }
    }

    /**
     *
     *
     * @constructor
     */
    InitInterval(){

        setInterval( data => {
            this.getServerDateTime();
        }, 120000);
    }


     newDate() {

            const localtime = new Date();
            const neu = new Date(localtime.getTime() + this.diff);
            return neu;
    }

}
