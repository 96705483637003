/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coupon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./coupon.component";
import * as i4 from "@angular/common/http";
import * as i5 from "../../../services/main.service";
import * as i6 from "@angular/router";
var styles_CouponComponent = [i0.styles];
var RenderType_CouponComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CouponComponent, data: {} });
export { RenderType_CouponComponent as RenderType_CouponComponent };
function View_CouponComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "contentCoupon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Willkommen in unserem Stammkundenbereich!"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "text-left spacing"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Bist du bei uns f\u00FCr Newsletter angemeldet oder bekommst du von uns die Werbung per Post, erh\u00E4ltst du dabei unsere Sparcoupons. "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h5", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Wie es funktioniert?"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "text-left spacing"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Lege deine Lieblingspizza, Burger, Salat oder Fingerfood zum Mindestbestellwert in den Warenkorb und gebe den Sparcoupon-code bei der Bestellung ein wie es hier angezeigt: "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "text-center containerBild"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "Bestelldetails"], ["class", "img-fluid rounded d-block "], ["src", "assets/img/dummy/bestelldetailsMP.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "text-left spacing"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Der Artikel aus dem Sparcoupon erscheint automatisch im Warenkorb und wird dir zusammen mit deiner Bestellung zugeliefert oder zur Abholung vorbereitet. "]))], null, null); }
function View_CouponComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "contentCoupon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "text-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Spar-Coupons oder Gutscheine kannst du bei uns auf viele verschiedene Weisen erhalten, z.B. per Newsletter, per Postwurfsendung, an deiner Rechnung usw., einfach die Augen aufhalten und kr\u00E4ftig sparen. "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Wie funktioniert die Eingabe?"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "text-left spacing"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Einfach auf das Warenkorbsymbol im Shop klicken und unter Spar-Coupons / Gutscheine deinen Code eingeben und einl\u00F6sen. So kannst du kr\u00E4ftig sparen! "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "text-center containerBild"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "Bestelldetails"], ["class", "img-fluid rounded d-block "], ["src", "assets/img/dummy/Sparcoupons_PB.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "text-left spacing"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Der Artikel aus dem Sparcoupon erscheint automatisch im Warenkorb und wird dir zusammen mit deiner Bestellung zugeliefert oder zur Abholung vorbereitet. "]))], null, null); }
export function View_CouponComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "static-page-layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "header-background-img header-wrapper"], ["style", "background-image: url('assets/img/header/Headerbilder_Produktgruppen_2184x656px_Spar-Coupon.jpg')"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "static-page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Spar-Coupon"])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "center-content-narrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h2", [["class", "text-center mp-font-medium-yellow"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Spar-Coupon"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponComponent_2)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.storeData == null) ? null : _co.storeData.master) === 1); _ck(_v, 12, 0, currVal_0); var currVal_1 = (((_co.storeData == null) ? null : _co.storeData.master) === 3); _ck(_v, 14, 0, currVal_1); }, null); }
export function View_CouponComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-coupon", [], null, null, null, View_CouponComponent_0, RenderType_CouponComponent)), i1.ɵdid(1, 114688, null, 0, i3.CouponComponent, [i4.HttpClient, i5.MainService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CouponComponentNgFactory = i1.ɵccf("app-coupon", i3.CouponComponent, View_CouponComponent_Host_0, {}, {}, []);
export { CouponComponentNgFactory as CouponComponentNgFactory };
