<div class="static-page-layout">

    <div class="row">

        <div class="col-md-12">

            <div class=" header-wrapper-small">
                <h1 class="static-page-title">Impressum</h1>
            </div>
        </div>
    </div>
    <ng-container>
        <div class="row">

            <div class="col-md-12">


                <div class="center-content">
                    <h2>Angaben gemäß § 5 TMG</h2>

                    <p>
                        <span *ngIf="storeData?.id === 2">Markus Hoffmann</span>
                        <span *ngIf="storeData?.id === 7">Markus Hoffmann</span>
                        <span *ngIf="storeData?.id === 4">Tim Gowitzke</span>
                        <span *ngIf="storeData?.id === 5">Hasher Ahmad</span>
                        <span *ngIf="storeData?.id === 6">Tim Gowitzke</span> <br/>
                        {{ storeData?.name }}<br/>
                        {{ storeData?.street }}<br/>
                        {{ storeData?.zip }} {{ storeData?.city }}
                    </p>


                    <h2>Kontakt</h2>
                    <p>Telefon: {{ storeData?.phone }}<br/>
                        <span *ngIf="storeData?.id === 2">Telefax: 03731-775090<br/></span>
                        E-Mail: {{ storeData?.email }}</p>

                    <h2 *ngIf="storeData?.id === 2">Umsatzsteuer-ID</h2>
                    <p *ngIf="storeData?.id === 2">Umsatzsteuer-Identifikationsnummer gemäß §27 a
                        Umsatzsteuergesetz:<br/>
                        266 661 956</p>
                    <h2 *ngIf="storeData?.id === 5">Steuernummer</h2>
                    <p *ngIf="storeData?.id === 5">68/101/04031</p>
                    <h2>Streitschlichtung</h2>
                    <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                            href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener">https://ec.europa.eu/consumers/odr</a>.<br/>
                        Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle teilzunehmen.</p>

                    <h3>Haftung für Inhalte</h3>
                    <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                        nicht
                        verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                        Umständen zu
                        forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
                    <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                        Gesetzen
                        bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                        Kenntnis
                        einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                        Rechtsverletzungen werden
                        wir diese Inhalte umgehend entfernen.</p>
                    <h3>Haftung für Links</h3>
                    <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
                        haben.
                        Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                        verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                        verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
                    <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                        Anhaltspunkte einer
                        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                        Links
                        umgehend entfernen.</p>
                    <h3>Urheberrecht</h3>
                    <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                        deutschen
                        Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                        außerhalb der
                        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                        Erstellers.
                        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch
                        gestattet.</p>
                    <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                        Dritter
                        beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem
                        auf eine
                        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                        Bekanntwerden
                        von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

                </div>


            </div>
        </div>
    </ng-container>

</div>
