<div class="mega-deal-page static-page-layout">
  <div class="row category-img">
    <div class="col-12">
        <picture>
            <!--<source media="(min-width: 120.5em)" srcset="assets/img/header/Headerbild_MegaDeal_656.webp" type="image/webp">
            <source media="(min-width: 120.5em)" srcset="assets/img/header/Headerbild_MegaDeal_656.jpg">
            <source media="(min-width: 75em)" srcset="assets/img/header/Headerbild_MegaDeal_400.webp" type="image/webp">
            <source media="(min-width: 75em)" srcset="assets/img/header/Headerbild_MegaDeal_400.jpg">
            <source media="(min-width: 36em)" srcset="assets/img/header/Headerbild_MegaDeal_255.webp" type="image/webp">-->
            <source media="(min-width: 36em)" srcset="/assets/img/MegaDeal/Meisterpizza_Headerbild_Obergruppen_2184x656px_MegaDeal_c.jpg">
            <!--<source srcset="assets/img/header/Headerbild_MegaDeal_180.webp" type="image/webp">-->
            <source srcset="/assets/img/MegaDeal/Meisterpizza_Headerbild_Obergruppen_304x170px_MegaDeal_c.jpg">
            <img class="img-fluid" src="/assets/img/MegaDeal/Meisterpizza_Headerbild_Obergruppen_304x170px_MegaDeal_c.jpg" alt="MegaDeal" />
        </picture>
        <!--<div class="category-headline-wrap">
            <h1 class="category-headline">Online-Mega-Deal</h1>
        </div>-->
    </div>
    <div class="col-12 my-6 my-md-8">
        <h2 class="text-center">Ab 3 Pizzen aus unserem Pizzasortiment:</h2>
        <div class="mega-deal-item-wrapper mt-4">
            <div class="mega-deal-item">
                <div class="mega-deal-item-size">
                    30er
                </div>
                <div class="mega-deal-item-price">
                    13,99 €
                </div>
            </div>
          <!--  <div class="mega-deal-item">
                <div class="mega-deal-item-size">
                    32er
                </div>
                <div class="mega-deal-item-price">
                    17,99 €
                </div>
            </div>-->
        </div>
        <div class="mt-6 mt-md-8 text-center">
            <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/11/pizza" class="mp-btn mp-btn-yellow-on-black mb-5">jetzt bestellen</a>
            <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/96/pizza" class="mp-btn mp-btn-yellow-on-black mb-5">jetzt bestellen</a>
            <p>Ab einer Bestellung von 3 Pizzen wird der Mega-Deal-Preis automatisch im Warenkorb berechnet. Der Abholrabatt ist beim Online-Mega-Deal ausgeschlossen.</p>
        </div>
    </div>
  </div>
</div>
