import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { WrapperComponent } from "./wrapper-components/wrapper/wrapper.component";
import { DashboardComponent } from "./wrapper-components/2-content/dashboard/dashboard.component";
import { ArticleListComponent } from "./wrapper-components/2-content/list-view/article-list/article-list.component";
import { ArticleDetailComponent } from "./wrapper-components/2-content/detail-view/article-detail/article-detail.component";
import { TestComponent } from "./test/test.component";
import { ServerDownComponent } from "./wrapper-components/3-other/server-down/server-down.component";
import { CartPageComponent } from "./wrapper-components/2-content/cart-page/cart-page.component";
import { SpecialOfferComponent } from "./wrapper-components/2-content/special-offer/special-offer.component";
import { AdressPageComponent } from "./wrapper-components/2-content/adress-page/adress-page.component";
import { MinOrderValueComponent } from "./wrapper-components/2-content/min-order-value/min-order-value.component";
import { LoginComponent } from "./wrapper-components/3-other/login/login.component";
import { UserComponent } from "./wrapper-components/3-other/user/user.component";
import { AuthGuard } from "./services/auth-guard.service";
import { AgbComponent } from './wrapper-components/4-footer/agb/agb.component';
import { AllergeneComponent } from './wrapper-components/4-footer/allergene/allergene.component';
import { FeedbackComponent } from './wrapper-components/4-footer/feedback/feedback.component';
import { ImpressumComponent } from './wrapper-components/4-footer/impressum/impressum.component';
import { JobsComponent } from './wrapper-components/4-footer/jobs/jobs.component';
import { CouponComponent } from './wrapper-components/4-footer/coupon/coupon.component';
import { NewsletterComponent } from './wrapper-components/4-footer/newsletter/newsletter.component';
import { NewsletterAnmeldungComponent } from './wrapper-components/4-footer/newsletter-anmeldung/newsletter-anmeldung.component';
import { NewsletterErfolgComponent } from './wrapper-components/4-footer/newsletter-erfolg/newsletter-erfolg.component';
import { SystempartnerComponent } from './wrapper-components/4-footer/systempartner/systempartner.component';
import { UeberunsComponent } from './wrapper-components/4-footer/ueberuns/ueberuns.component';
import { DatenschutzComponent } from './wrapper-components/4-footer/datenschutz/datenschutz.component';
import { PizzaPartyMegaDealComponent } from "./wrapper-components/2-content/pizza-party-mega-deal/pizza-party-mega-deal.component";
import { MittagsAngebotComponent } from "./wrapper-components/2-content/mittags-angebot/mittags-angebot.component";
import { WelcomeComponent } from './wrapper-components/welcome/welcome.component';
import { PageNotFoundComponent } from './wrapper-components/3-other/page-not-found/page-not-found.component';
import { Step1Component } from './wrapper-components/welcome/step-1/step-1.component';
import { Step2Component } from './wrapper-components/welcome/step-2/step-2.component';
import { Step3Component } from './wrapper-components/welcome/step-3/step-3.component';
import { Step4Component } from './wrapper-components/welcome/step-4/step-4.component';
import { Step5Component } from './wrapper-components/welcome/step-5/step-5.component';

import { RestaurantComponent } from './wrapper-components/4-footer/restaurant/restaurant.component';
import { FooterFDComponent } from './wrapper-components/4-footer/footer-fd/footer-fd.component';
import { LiefergebieteComponent } from './wrapper-components/4-footer/liefergebiete/liefergebiete.component';
import { OeffnungszeitenComponent } from './wrapper-components/4-footer/oeffnungszeiten/oeffnungszeiten.component';
import { ZahlungsartenComponent } from './wrapper-components/4-footer/zahlungsarten/zahlungsarten.component';
import { ShopinfosComponent } from './wrapper-components/4-footer/shopinfos/shopinfos.component';
import { CouponsPageComponent } from './wrapper-components/2-content/coupons-page/coupons-page.component';

const routes: Routes = [
    {
        path: 'shop', component: WrapperComponent, children: [
            /** show shop dashboard. where user can navigate to subpages for products etc. */
            { path: '', component: DashboardComponent },
            {
                path: 'bestellen', children: [
                    { path: ':id_mc/:category', component: ArticleListComponent },
                    { path: ':id_mc/:category/:id_a/:name/:size', component: ArticleDetailComponent },
                    { path: ':id_mc/:category/:id_a/:name/:size/:edit', component: ArticleDetailComponent },
                    { path: 'warenkorb-index', component: CartPageComponent },
                    { path: 'warenkorb', component: CartPageComponent },
                    { path: 'adresseingabe', component: MinOrderValueComponent },
                    { path: 'aktion', component: SpecialOfferComponent },
                    { path: 'aktion/:id_a/:name/:size', component: ArticleDetailComponent },
                    { path: 'coupons', component: CouponsPageComponent },
                    { path: '**', redirectTo: './' },
                    { path: '', component: DashboardComponent },
                ]
            },

            { path: 'test', component: TestComponent },
            { path: 'login', component: LoginComponent },
            { path: 'agb', component: AgbComponent },
            { path: 'allergene', component: AllergeneComponent },
            { path: 'feedback', component: FeedbackComponent },
            { path: 'impressum', component: ImpressumComponent },
            { path: 'jobs', component: JobsComponent },
            { path: 'coupon', component: CouponComponent },
            { path: 'newsletter', component: NewsletterComponent },
            { path: 'newsletter-anmeldung', component: NewsletterAnmeldungComponent },
            { path: 'newsletter-erfolg', component: NewsletterErfolgComponent },
            { path: 'newsletter-success', component: NewsletterComponent },
            { path: 'newsletter-optout-success', component: NewsletterComponent },
            { path: 'systempartner', component: SystempartnerComponent },
            { path: 'ueberuns', component: UeberunsComponent },
            { path: 'restaurant', component: RestaurantComponent },
            { path: 'datenschutz', component: DatenschutzComponent },
            { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
            { path: 'pizza-party-mega-deal', component: PizzaPartyMegaDealComponent },
            { path: 'mittags-angebot', component: MittagsAngebotComponent },

            { path: 'footer-fd', component: FooterFDComponent },
            { path: 'liefergebiete', component: LiefergebieteComponent },
            { path: 'oeffnungszeiten', component: OeffnungszeitenComponent },
            { path: 'zahlungsarten', component: ZahlungsartenComponent },
            { path: 'shopinfos', component: ShopinfosComponent },
        ]
    },
    /** declare route for welcome page - guides user and collects basic information */
    {
        path: 'willkommen', component: WelcomeComponent, children: [
            { path: 'step-1', redirectTo: '/willkommen/start', pathMatch: 'full'},
                { path: 'step-1/edit', redirectTo: '/willkommen/start', pathMatch: 'full'},
            { path: 'step-2', redirectTo: '/willkommen/start', pathMatch: 'full'},
                { path: 'step-2/edit', redirectTo: '/willkommen/start', pathMatch: 'full'},
            { path: 'step-3', redirectTo: '/willkommen/start', pathMatch: 'full'},
                { path: 'step-3/edit', redirectTo: '/willkommen/start', pathMatch: 'full'},
            { path: 'step-4', redirectTo: '/willkommen/start', pathMatch: 'full'},
                { path: 'step-4/edit', redirectTo: '/willkommen/start', pathMatch: 'full'},
            { path: 'step1', redirectTo: '/willkommen/start', pathMatch: 'full'},
                { path: 'step1/edit', redirectTo: '/willkommen/start', pathMatch: 'full'},
            { path: 'step2', redirectTo: '/willkommen/start', pathMatch: 'full'},
                { path: 'step2/edit', redirectTo: '/willkommen/start', pathMatch: 'full'},
            { path: 'step3', redirectTo: '/willkommen/start', pathMatch: 'full'},
                { path: 'step3/edit', redirectTo: '/willkommen/start', pathMatch: 'full'},
            { path: 'step4', redirectTo: '/willkommen/start', pathMatch: 'full'},
                { path: 'step4/edit', redirectTo: '/willkommen/start', pathMatch: 'full'},
            { path: 'start', component: Step5Component },
            { path: 'start/edit', component: Step5Component },
            // { path: 'welcome-mp', component: WelcomeMPComponent },
            //     { path: 'welcome-mp', component: WelcomeMPComponent },
        ]
    },


    { path: '404', component: PageNotFoundComponent },
    { path: 'server-offline', component: ServerDownComponent },
    {
        path: '',
        redirectTo: '/willkommen/start',
        pathMatch: 'full'
    },

    /** MUST BE LAST!! Redirects to home, is user types in invalid url */
    // TODO: redirect to 404 page
    { path: '**', redirectTo: '404' },


];


@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class RoutingModule {
}
