<!--<button class="log-button" (click)="onLog()">Log Dashboard Component</button>-->

<ng-container *ngIf="!ready">
    <div class="text-center color-primary">
        <svg-icon src="assets/icons/icon-loading.svg" [svgStyle]="{ 'fill':'currentColor' }"></svg-icon>
    </div>
</ng-container>
<ng-container *ngIf="ready">
    <div *ngIf="storeData?.master === 3" class="container-full home-content-wrapper" id="home-content">
        <app-element-slider element="sliderDashboard"></app-element-slider>

        <app-element-categories element="categoriesDashboard"></app-element-categories>
        <div class="row sortiment">


            <div class="col-md-6 mb-6 box-cupon1">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/coupon">
                    <picture><img src="assets/img/startseite/Spar-Coupon.jpg" class="img-fluid" alt=""></picture>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>

            <div class="col-md-6 mb-6 box-cupon1">
                <!-- Hoya -->
                <a *ngIf="storeData?.id === 4"
                   class="mp-box-link d-block position-relative text-center"
                   routerLink="/shop/bestellen/70/Mittagstisch">

                    <picture><img src="assets/img/startseite/Mittagsangebot.jpg" class="img-fluid" alt=""></picture>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>

                <!-- Vechta,Verden, Achim Jobs-->
                <a *ngIf="storeData?.id === 5 || storeData?.id === 6 || storeData?.id === 8 " class="mp-box-link d-block position-relative text-center"
                   routerLink="/shop/jobs">
                    <picture><img src="assets/img/startseite/PizzaBlitz_Kachel_760x480px_Jobangebot.jpg"
                                  class="img-fluid" alt=""></picture>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>

            </div>
        </div>


        <div *ngIf="storeData?.id === 4 || storeData?.id === 5" class="row boxes-home">
            <div class="col-sm-6  mb-6 box-news">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/newsletter">
                    <figure class="mb-5 mb-xl-0">
                        <img src="assets/img/icons/newsletter-icon_homepage.png" class="">
                    </figure>
                    <h4>Newsletter</h4>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>

            <div class="col-sm-6  mb-6 box-jobs ">
                <a class="mp-box-link d-block position-relative text-center" routerLink="/shop/jobs">
                    <figure class="mb-5 mb-xl-0">
                        <img src="assets/img/icons/jobs-icon_homepage.png" class="">
                    </figure>
                    <h4>Stellenangebote</h4>
                    <button class="mp-btn mp-btn-icon">
                        <svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon>
                    </button>
                </a>
            </div>
        </div>
    </div>


</ng-container>
