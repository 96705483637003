<div class="static-page-layout">
    <ng-container *ngIf="optinSuccess">
        <div class="row">
            <!--<div class="col-md-12">
                <div class="header-background-img header-wrapper"
                     style="background-image: url('assets/img/header/header-newsletter.png')">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>-->

            <div class="col-md-12">
                <!-- <div *ngIf="storeData?.master === 1" class="header-background-img header-wrapper"
                     style="background-image: url('assets/img/header/header-newsletter.png')">-->
                <div *ngIf="storeData?.master === 1" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_MP + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>

                <div *ngIf="storeData?.master === 3" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_PB + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <div class="text-center mt-7">
                        <svg-icon src="assets/icons/icon_check_black.svg"></svg-icon>
                    </div>
                    <h2 class="mp-font-medium-yellow text-center">Deine Anmeldung ist abgeschlossen</h2>
                    <p class="text-center spacing">Du erhältst zukünftig tolle Aktionen und leckere Gutscheine direkt in
                        Dein Postfach.</p>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="optoutSuccess">
        <div class="row">
            <!--<div class="col-md-12">
                <div class="header-background-img header-wrapper"
                     style="background-image: url('assets/img/header/header-newsletter.png')">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>-->

            <div class="col-md-12">
                <div *ngIf="storeData?.master === 1" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_MP + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>

                <div *ngIf="storeData?.master === 3" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_PB + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <div class="text-center mt-7">
                        <svg-icon src="assets/icons/icon_check_black.svg"></svg-icon>
                    </div>
                    <h2 class="mp-font-medium-yellow text-center">Schade, dass du gehst!</h2>
                    <p class="text-center spacing">

                    <p>
                        Du hast dich erfolgreich vom Meisterpizza Newsletter abgemeldet.
                    </p>
                    <p>
                        Wenn du Deinen Entschluss doch bereust, dann kannst du dich auf www.meisterpizza.de jederzeit
                        wieder anmelden. Uns würde es freuen.
                    </p>
                    <p>

                        Dein Meisterpizza-Team Freiberg</p>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!formSent && !optinSuccess && !optoutSuccess">
        <div class="row">
            <!-- <div class="col-md-12">
                <div class="header-background-img header-wrapper"
                     style="background-image: url('assets/img/header/header-newsletter.png')">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>-->

            <div class="col-md-12">
                <div *ngIf="storeData?.master === 1" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_MP + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>

                <div *ngIf="storeData?.master === 3" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_PB + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <p *ngIf="storeData?.master === 1" class="highlight text-center spacing">Meisterhaft informiert!
                        Abonniere unseren Newsletter,
                        wenn du regelmäßig alle Infos zu unseren Produkten, Aktionen und Gutscheinen erhalten möchtest.</p>
                    <p *ngIf="storeData?.master === 3" class="text-center spacing text-color-white">Blitzschnell
                        informiert! Abonniere unseren Newsletter,
                        wenn du regelmäßig alle Infos zu unseren Produkten, Aktionen und Gutscheinen erhalten möchtest.</p>
                    <p class="text-center spacing">Nach Absenden des Formulars bekommst du eine E-Mail mit einem Bestätigungslink.
                        Nach Anklicken des Links ist die Anmeldung bestätigt. </p>
                </div>
            </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <form *ngIf="storeData?.id === 2" class="mp-form" [formGroup]="newsletterFormGroup"
                          (ngSubmit)="onSubmit()">
                        <div class="form-group"
                             [ngClass]="{ 'error-message' : (email.dirty || email.touched) && email.invalid }">
                            <label>E-Mail *</label>
                            <mat-form-field class="example-full-width" appearance="fill">
                                <input matInput type="text" formControlName="email">
                            </mat-form-field>

                            <span class="error-message" *ngIf="(email.dirty || email.touched) && email.invalid">
                                Dies ist keine gültige E-Mail Adresse!
                            </span>
                        </div>

                        <div class="form-group">
                            <label style="margin-bottom: 10px;">Anrede</label>
                            <mat-radio-group name="title" formControlName="salutation">
                                <mat-radio-button value="Herr">Herr</mat-radio-button>
                                <mat-radio-button value="Frau" class="ml-3">Frau</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <div class="form-group">
                            <label>Vorname</label>
                            <mat-form-field class="example-full-width" appearance="fill">
                                <input matInput id="firstName" name="firstName" formControlName="firstName">
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <label>Nachname</label>
                            <mat-form-field class="example-full-width" appearance="fill">
                                <input matInput name="lastName" formControlName="lastName">
                            </mat-form-field>
                        </div>

                        <div class="form-group checkbox-top">
                            <mat-checkbox formControlName="dataProtect">Ich habe die Datenschutzerklärung zur Kenntnis
                                genommen. Ich stimme zu, dass meine Angaben und Daten aus dem Formular zur Beantwortung
                                meiner Anfrage elektronisch erhoben und gespeichert werden. Mehr Informationen zum
                                Umgang mit Nutzerdaten finden Sie in unserer Datenschutzerklärung. Sie können Ihre
                                Einwilligung jederzeit per E-Mail an kontakt@meisterpizza.de widerrufen.
                            </mat-checkbox>
                        </div>

                        <!-- <button class="margin-center mb-5 mt-5 mp-btn mp-btn-black" (click)="formSent = !formSent">Newsletter bestellen</button> -->
                        <button class="margin-center mb-7 mt-7 mp-btn mp-btn-black" type="submit"
                                [disabled]="!newsletterFormGroup.valid">Jetzt abonnieren
                        </button>
                    </form>
                    <iframe *ngIf="storeData?.id === 4" width="540" height="1180"
                            src="https://49095320.sibforms.com/serve/MUIEAFwjbzs5bGVulMR4wQpwUupHbZdJM9ZlkMWXvbp0m5EuidpGwC5VFbScDi9J6uDO6QX_0SHBb4e_O-PvTwxYMySSukxNvNJ1N0JclsA7UXNyCVbGdkCrNQxni5SNx3zh0PhJt0LxWa1vftCKNs5lCFe5CL2tHXhD-5vXlrhtUTq5MqZQCSIlJXL_vDdqgV2yd5IesK9vouV_"
                            frameborder="0" scrolling="auto" allowfullscreen
                            style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
                    <iframe *ngIf="storeData?.id === 5" width="540" height="1180"
                            src="https://49095320.sibforms.com/serve/MUIEAKlr5XsTMNjryrze7o4_Lz4qsb6pRB5wI-2Lraac0_57oBBpIXX7bkem_pE1maKwN6kXdCFHDIsyZlmZ73Z24T2aPjp-ifEb6eacIbLSCTe1w6kXG4D8mwNvIpQnIKL7S1_2S-mIOCuVHM4_S-KXcLFLCFkNOQzMYjfrfOyNsuhFbMyELgP3DkGyyLYQl944q_unzaSnSvEQ"
                            frameborder="0" scrolling="auto" allowfullscreen
                            style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
                    <iframe *ngIf="storeData?.id === 6" width="540" height="1180"
                            src="https://49095320.sibforms.com/serve/MUIEAJFgoB6AH62cr-bAjEw2XDGT-FN6lf5yxKFED9WvUIPyg4tP42Ss-E4nLUQZ3nw2YQf6mqtyt-D7oLaohQYnuXscNSo5cRacYg13kbXqyAIeG1LL9VsSDepVbFZH5YEog7LcG3DHJnhUKsLwJl5bcMOmz7P4U1tABFPSmYHaPTLYFKqCd5wgPcSa0NETTPYyaFotvX97nWEk"
                            frameborder="0" scrolling="auto" allowfullscreen
                            style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
                    <iframe *ngIf="storeData?.id === 7" width="540" height="1180"
                            src="https://faba87c5.sibforms.com/serve/MUIEAKxM3s6biWUze34Bon28GZMLsk7ex-AKYWrBvdtSKIaGnNH2DW8v3Nzoyps6KFcWjn2zuQTgw3-Rupb4X6n6386ZYJLNw59qvaBZBQsoNYs5PL8HtfP1MqnFmiJlKU0BzhSTaQJO6_B8jnw6HvowMQ0Scmle3vZDEljE9QUsnpFDGsmuDP8XTHlDHJn2sVebo8HhmWDtnFNZ"
                            frameborder="0" scrolling="auto" allowfullscreen
                            style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
                    <iframe *ngIf="storeData?.id === 8" width="540" height="1180"
                            src="https://49095320.sibforms.com/serve/MUIEAEyAwWswEvuBFAo9oPG4jYfSWVlFl7p4uVtAv8V6LoFK-_g2c2T7MXx4yXcdWKd_6vIBURH7GoOYtlphFVyvsUKBulCCT8Jey229TOZkFqcJ_3yvjbkZutaXrH1d0KKV1pQVdHexeNq8W726KOFMaJ_XvFUb7yrHNeAwjwfL4ZFumsy_PuJc3vrV24oZw82HtrA11GrvJ9oO"
                            frameborder="0" scrolling="auto" allowfullscreen
                            style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="formSent">
        <div class="row">
            <!--<div class="col-md-12">
                <div class="header-background-img header-wrapper"
                     style="background-image: url('assets/img/header/header-newsletter.png')">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>-->

            <div class="col-md-12">
                <div *ngIf="storeData?.master === 1" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_MP + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>

                <div *ngIf="storeData?.master === 3" class="header-background-img header-wrapper headerBild"
                     [style.backgroundImage]="'url(' + headerbild_PB + ')'">
                    <h1 class="static-page-title">Newsletter</h1>
                </div>
            </div>

            <div class="col-md-12">
                <div class="center-content-narrow">
                    <div class="text-center mt-7">
                        <svg-icon src="assets/icons/icon_check_black.svg"></svg-icon>
                    </div>
                    <h2 class="mp-font-medium-yellow text-center">Deine Anmeldung wurde erfolgreich abgesendet.</h2>
                    <p class="text-center spacing">Wir haben Dir eine E-Mail mit einem Bestätigungslink geschickt. Nach
                        Anklicken ist die Newsletteranmeldung bestätigt.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h2 class="mp-font-medium-white text-center mt-7 mb-7">Und jetzt...Hunger?</h2>
            </div>
        </div>

        <div class="row sortiment center-content">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/1/pizza">
                    <div>
                        <img src="assets/img/startseite/pizza-sortiment_kategorie-startseite.jpg" class="img-fluid">
                        <h2>Pizza-<br>Sortiment</h2>
                    </div>
                </a>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/3/burger">
                    <div>
                        <img src="assets/img/startseite/burger-sortiment_kategorie-startseite.jpg" class="img-fluid">
                        <h2>Burger-<br>Sortiment</h2>
                    </div>
                </a>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/4/salat">
                    <div>
                        <img src="assets/img/startseite/salat-sortiment_kategorie-startseite.jpg" class="img-fluid">
                        <h2>Salat-<br>Sortiment</h2>
                    </div>
                </a>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 mp-mb-40">
                <a class="" routerLink="/shop/bestellen/6/fingerfood">
                    <div>
                        <img src="assets/img/startseite/fingerfood-sortiment_kategorie-startseite.jpg"
                             class="img-fluid">
                        <h2>Fingerfood-<br>Sortiment</h2>
                    </div>
                </a>
            </div>
            <button class="margin-center mb-7 mt-7 mp-btn mp-btn-yanone-on-black" routerLink="/shop">lecker bestellen
            </button>
        </div>
    </ng-container>
</div>
