import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pizza-party-mega-deal',
  templateUrl: './pizza-party-mega-deal.component.html',
  styleUrls: ['./pizza-party-mega-deal.component.scss']
})
export class PizzaPartyMegaDealComponent implements OnInit {

  storeData: any;

  constructor() { }

  ngOnInit() {
    this.storeData = JSON.parse(localStorage.getItem('storedata'));
  }

}
