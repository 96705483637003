import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MainService } from '../../../services/main.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})

export class NewsletterComponent implements OnInit {

    // check if form is submitted
    formSent = false;
    optinSuccess = false;
    optoutSuccess = false;

    storeData: any;

    // headerBilder
    headerbild_MP = "/assets/img/header/HG_Headerbild_Mobil_304x170px_Newsletter_MP.jpg";
    headerbild_PB = "/assets/img/header/HG_Headerbild_Mobil_304x170px_Newsletter_PB.jpg";
    headerbild_MP_Desktop = "/assets/img/header/Headerbild_Obergruppen_Newsletter_MP.jpg";
    headerbild_PB_Desktop = "/assets/img/header/Headerbilder_Produktgruppen_2184x656px_Newsletter.jpg";
    sizeSmall = true;


    // create new reactive formgroup for form
    // https://angular.io/guide/reactive-forms
    newsletterFormGroup = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email]),
        salutation: new FormControl(null),
        firstName: new FormControl(null),
        lastName: new FormControl(null),
        dataProtect: new FormControl(false, Validators.requiredTrue)
    });

    constructor(
        private http: HttpClient,
        private main_service: MainService,
        private router: Router,
    ) {


        if (this.router.url === '/shop/newsletter-success') {
            this.optinSuccess = true;
        }
        if (this.router.url === '/shop/newsletter-optout-success') {
            this.optoutSuccess = true;
        }

    }

    ngOnInit() {
        this.storeData = JSON.parse(localStorage.getItem('storedata'));
        window.onresize = this.setHeaderbild;
        this.setHeaderbild();

    }

    // getter for form elements
    get email() {
        return this.newsletterFormGroup.get('email');
    }

    // submit the newsletter form
    onSubmit() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        const url = this.main_service.getFrontendUrl() + '/newsletter/subscribe';

        this.http.post(url, JSON.stringify(this.newsletterFormGroup.value), httpOptions)
            .subscribe(
                (res: any) => {
                    this.formSent = true;
                    window.scroll(0, 0);
                },
                err => {

                }
            );
    }

    // Headerbild setzen
    setHeaderbild() {
        let small = window.matchMedia('(max-width: 576px)').matches; //false oder true
        if (small == this.sizeSmall) return;
        this.sizeSmall = small;
        /*console.log("Kleines Bild = ",small);*/
        if (small === false) {
            this.headerbild_MP = this.headerbild_MP_Desktop;
            this.headerbild_PB = this.headerbild_PB_Desktop;
            // console.log(this.headerbild_MP, "MP-DESKTOP");
            // console.log(this.headerbild_PB, "PB-DESKTOP");
        }
        else {
            // console.log(this.headerbild_MP, "MP-MOBIL");
            // console.log(this.headerbild_PB, "PB-MOBIL");
        }
    }

}
