<div class="row category-img margin">
    <div class="col-12 headerBild-container d-flex flex-column p-0">
        <div class="header-bild-container coupons_bg">
            <h1>Coupons einlösen</h1>
            <div class="coupons_wrap">
                <div class="item">
                    <svg fill="#a32025" width="auto" height="94px" viewBox="-1.7 0 20.4 20.4" class="cf-icon-svg">
                        <path d="M16.417 10.283A7.917 7.917 0 1 1 8.5 2.366a7.916 7.916 0 0 1 7.917 7.917zM5.909 9.536a1.812 1.812 0 1 0-1.812-1.813 1.814 1.814 0 0 0 1.812 1.813zm0-1.109a.704.704 0 1 1 .704-.704.705.705 0 0 1-.704.704zm5.72-1.402a.792.792 0 1 0-1.316-.88l-4.924 7.372a.792.792 0 0 0 1.317.88zm1.286 5.837a1.812 1.812 0 1 0-1.812 1.812 1.814 1.814 0 0 0 1.812-1.812zm-1.109 0a.704.704 0 1 1-.703-.704.705.705 0 0 1 .703.704z"/>
                    </svg>
                    <div class="num">1.</div>
                    <p>Wähle einen Coupon aus</p>
                </div>
                <div class="item arr">
                    <svg fill="#a32025" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                    </svg>
                </div>
                <div class="item">
                    <svg fill="#a32025" width="auto" height="94px" viewBox="-1 0 19 19"class="cf-icon-svg">
                        <path d="M16.417 9.579A7.917 7.917 0 1 1 8.5 1.662a7.917 7.917 0 0 1 7.917 7.917zm-5.629-.421v-.21H8.27a.267.267 0 0 1-.265-.265V6.176H5.054a.317.317 0 0 0-.317.316v7.612a.317.317 0 0 0 .317.316h5.418a.317.317 0 0 0 .316-.316V9.158zM6.21 5.384h2.635a.792.792 0 0 1 .505.228l.128.128V4.703H6.526a.318.318 0 0 0-.317.317zm2.565.807v1.99h2zm1.472.516h2.001l-2-1.988zm2.014.978v-.21h-1.037l.127.127a.79.79 0 0 1 .23.483v4.863h.364a.317.317 0 0 0 .316-.317V7.685z"/>
                    </svg>
                    <div class="num">2.</div>
                    <p>Kopiere den Code</p>
                </div>
                <div class="item arr">
                    <svg fill="#a32025" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                    </svg>
                </div>
                <div class="item">
                    <svg fill="#a32025" width="auto" height="94px" viewBox="-1 0 19 19" class="cf-icon-svg">
                        <path d="M16.417 9.579A7.917 7.917 0 1 1 8.5 1.662a7.917 7.917 0 0 1 7.917 7.917zm-3.34-2.323a.63.63 0 0 0-.628-.628H5.892l-.436-1a.384.384 0 0 0-.351-.23H3.68a.384.384 0 1 0 0 .768h1.173l1.785 4.096a.37.37 0 0 0-.087-.01 1.161 1.161 0 1 0 0 2.322h.042a.792.792 0 1 0 .864 0h3.452a.792.792 0 1 0 .864 0h.565a.384.384 0 1 0 0-.767H6.55a.393.393 0 0 1 0-.787.38.38 0 0 0 .098-.013l5.803-.602a.714.714 0 0 0 .625-.694z"/>
                    </svg>
                    <div class="num">3.</div>
                    <p>Füge den Code in deinen <br>Warenkorb ein</p>
                </div>
            </div>

        </div>
    </div>
</div>
<div *ngIf="!noCoupons" class="article-list-row row">
    <ng-container *ngFor="let coupon of couponsArray, let i = index">
        <div class="col-12 col-md-6 p-1 article-box-drinks article-box">
            <div class="d-flex flex-column bg-black-dark h-100">
                <div class="p-1">Spar Coupon</div>
                <img class="" src="{{ coupon.imgLink }}">
                <div class="p-1">ONLINECODE:
                    <p id="{{coupon.couponCode}}" class="fLarge">
                        <strong>{{coupon.couponCode}}</strong>&nbsp;&nbsp;&nbsp;
                        <a (click)="copyMessage(coupon.couponCode)" title="kopieren" class="mp-btn mp-btn-newsletter">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                                class="bi bi-copy" viewBox="0 0 20 20">
                                <path fill-rule="evenodd"
                                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                            </svg>
                        </a>
                    </p>
                    <p id="{{coupon.couponCode}}_copied" class="copyMessage" style="display: none;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                            class="bi bi-check-square" viewBox="0 0 20 20">
                            <path
                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path
                                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                        </svg> Kopiert
                    </p>
                </div>
                <div class="p-1">{{coupon.description}}</div>
            </div>
        </div>
    </ng-container>
</div>
<div *ngIf="noCoupons" class="article-list-row row">
    <h2 class="mx-auto">Momentan haben wir leider keine aktiven Coupons. Nutze unsere Mega Deals oder andere Angebote.
    </h2>
</div>