import {Component, ElementRef, QueryList, OnInit, ViewChildren, HostBinding, AfterViewChecked, Input, Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {catchError, retry, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../../../services/main.service';
declare var jQuery: any;




@Component({
    selector: 'app-element-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeElementComponent implements OnInit  {

    data: any =[];
    ready: boolean = false;
    image_url;
    dataHasImg = false;
    @Input('element') element: string;

  constructor(
      private http: HttpClient,
      private main_service: MainService,
  ) {


  }


  ngOnInit() {

      this.image_url = this.main_service.image_url;
      const url = this.main_service.getFrontendUrl() + '/elementByName/' + this.element;
      this.http.get(url).subscribe(data => {

          this.data = data;
          if ((Array.isArray(this.data.image) && this.data.image.length) || (typeof this.data.image === 'object' && this.data.image.jpg)) {
            this.dataHasImg = true;
          }
          this.ready = true;

      });

  }



}


