import { Component, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Subscription } from "rxjs/internal/Subscription";
import { FrontendService } from "../../../services/frontend.service";
import { MainService } from "../../../services/main.service";
import {CartService} from '../../../services/cart.service';
import {forkJoin, of} from 'rxjs';
declare var jQuery: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    // public stuff  - - - - - - - - - - - - - - - -
    slider_data = [];       // beinhaltet Daten der Sliderfolien
    image_url: string;      // Bilderpfad
    ready = false;
    /* Collect store data from api backend */
    storeData: any;


    // private stuff - - - - - - - - - - - - - - - -
    private subscriptions: Subscription[] = [];

    @ViewChild('carouselNext') carouselNext: ElementRef<HTMLElement>;


    // constructor   - - - - - - - - - - - - - - - -
    constructor(
        private frontend_service: FrontendService,
        private main_service: MainService,
        private cart_service: CartService
    ) {
        forkJoin([
            /* make api calls */
            this.frontend_service.getStoreData(),
        ])
            .subscribe(
                data => {
                    this.distributeServiceDataOnSuccess(data);
                },
                err => console.error(err)
            );
    }

    // init functions
    ngOnInit() {
        this.cart_service.checkInstore();
        // this.subscriptions.push(this.frontend_service.getSliderInfo().subscribe(
        //     (data: any[]) => {
        //         this.slider_data = data;
        //         console.log('this.slider_data: %o', this.slider_data);
        //         this.ready = true;
        //     }));


        this.image_url = this.main_service.image_url;
        this.ready = true;

        // setTimeout(() => {
        //     // this.triggerFalseClick();
        //     // console.log('TIMEOUT CLICK');
        //     // console.log('ELEMENT: %o', el);

        // }, 1000);
    }

    distributeServiceDataOnSuccess(data: any) {
        console.log('Service Data DASHBOARD Component: %o', data);
        /* distribute the api call response to the corresponding vars */
        this.storeData = data[0];
        this.ready = true;
    }

    ngAfterViewInit() {
        // setTimeout(() => {
        //     console.log('TIMEOUT CLICK');
        //     let el: HTMLElement = this.carouselNext.nativeElement;
        //     el.click();
        // }, 1000);



    }

    ngAfterViewChecked () {


    }
    // triggerFalseClick() {
    //
    // }


    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions

    onLog() {

    }

    click() {

    }

    onGoToSpecialOffer() {

    }
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions

}
