import {Inject, Injectable} from '@angular/core';
import { Observable } from "rxjs/internal/Observable";
import { MainService } from "./main.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RequestOptions, Headers, Response } from "@angular/http";
import { catchError, retry, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { of } from "rxjs/internal/observable/of";
import { MasterCat } from "../../_classes/MasterCat";
import { Article } from "../../_classes/Article";
import { Subject, ReplaySubject, interval } from 'rxjs';
import {DOCUMENT} from '@angular/common';
import { ArticleGroup } from '../../_classes/ArticleGroup';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    }),
    // responseType: 'text' as 'json'
    // responseType: 'json'
};

@Injectable({
    providedIn: 'root'
})
export class FrontendService {
    // public stuff  - - - - - - - - - - - - - - - -
    // create observable for order success
    // orderSuccessful: boolean;
    private orderResponseErrorSubject = new ReplaySubject<any>();
    private amazonPayGetOrderResponseSubject = new ReplaySubject<any>();
    private amazonPayConfirmOrderResponseSubject = new ReplaySubject<any>();
    // the WINORDER TRACKING STATUS from the db entry; not "status"
    private checkWinorderTrackingStatusSubject = new ReplaySubject<any>();
    private getMollieUrlSubject = new ReplaySubject<any>();
    
    // orderSuccessful$ = this.orderSuccessfulSubject.asObservable();
    checkWinorderTrackingStatus: any;
    // orderStatus: any;

    storeData: any;
    test: any;
    mollieresp = '';
    // private stuff - - - - - - - - - - - - - - - -

    // constructor   - - - - - - - - - - - - - - - -
    constructor(
        private main_service: MainService,
        private http: HttpClient,
        private router: Router,
        @Inject(DOCUMENT) private document: any,
    ) {
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // public functions
    public getOrderSuccessful(): Observable<any> {
        return this.orderResponseErrorSubject.asObservable();
    }

    public getAmazonPayOrderResponse(): Observable<any> {
        return this.amazonPayGetOrderResponseSubject.asObservable();
    }

    public getAmazonPayConfirmOrderResponse(): Observable<any> {
        return this.amazonPayConfirmOrderResponseSubject.asObservable();
    }

    public getWinorderTrackingStatus(): Observable<any> {
        return this.checkWinorderTrackingStatusSubject.asObservable();
    }

    public getMollieUrl(): Observable<any> {
        return this.getMollieUrlSubject.asObservable();
    }

    // holt die Daten zu allen Hauptkategorien aus der DB + übergiebt sie - zB für Anzeige in der Navigation
    getCategories(): Observable<MasterCat[]> {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_categories';
        const url = this.main_service.getFrontendUrl() + '/productgroups';
        return this.http.get<MasterCat[]>(url)
            .pipe(
                retry(3),
                tap(data => this.log('fetched categories from ' + url)),
                catchError(this.handleError<MasterCat[]>(`getHero id=${name}`))
            );
    }

    getMasterCategories(): Observable<MasterCat[]> {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_categories';
        const url = this.main_service.getFrontendUrl() + '/productgroupmaster';
        return this.http.get<MasterCat[]>(url)
            .pipe(
                retry(3),
                tap(data => this.log('fetched categories from ' + url)),
                catchError(this.handleError<MasterCat[]>(`getHero id=${name}`))
            );
    }

    // erstellt Liste alelr Artikel einer Kategorie und gibt sie refactored zurück (Listenansicht)
    getArticleListByCategory(sub_cat_id: number, delivType: string, delivDate: string): Observable<Article[]> {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_article_list&category=' + sub_cat_id;
        const url = this.main_service.getFrontendUrl() + '/productgroupmaster/' + sub_cat_id + '/products/' + delivType + '/' + delivDate;
        return this.http.get<Article[]>(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched article list from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // übergibt alle Infos eines Artikels zu einer bestimmten ID (Detailansicht)
    getArticleByID(sub_cat_id: number) {
        
        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        var delivType = (sessionStorage.getItem('pickup') === '1' ) ? 'pickup' : 'delivery';

        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_article_detail&id=' + sub_cat_id;
        const url = this.main_service.getFrontendUrl() + '/products/' + sub_cat_id + '/' + delivType + '/' + delivDate;
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched article detail from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // same wie get_article_detail nur eben für Aktionsartikel
    getSpArticleByID(sub_cat_id: number) {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_special_offer_article_detail&id=' + sub_cat_id;

        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        var delivType = (sessionStorage.getItem('pickup') === '1' ) ? 'pickup' : 'delivery';

        const url = this.main_service.getFrontendUrl() + '/products/' + sub_cat_id + '/' + delivType + '/' + delivDate;
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched article detail from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // gibt alle Zutaten geordnet nach Kategorie zurück, mit der Artikel einer SubCat erweitert werden kann
    // is build on $request->segment(x) on server side, do not change the order of url segments!!!
    getIngListBySubCat(sub_cat_id: number) {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_ingredient_list&id=' + sub_cat_id;
        const url = this.main_service.getFrontendUrl() + '/productgroups/' + sub_cat_id + '/ingredients';
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched ing list for a subcat from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    getIngListBySubCatReload(sub_cat_id: number, ingridientsList, amount, operationType: any = false, current_size: any = false) {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_ingredient_list&id=' + sub_cat_id;
        const url = this.main_service.getFrontendUrl() + '/productgroups/' + sub_cat_id + '/ingredients';

        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        var delivType = (sessionStorage.getItem('pickup') === '1' ) ? 'pickup' : 'delivery';

        let arr = {
            'ingridientsArr': ingridientsList,
            'productAmount': amount,
            'deliveryDate': delivDate,
            'deliveryType': delivType,
            'operationType': operationType,
            'current_size': current_size
        };

        return this.http.post(url,JSON.stringify(arr), httpOptions)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched ing list for a subcat from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    /* Get all ingredients by product group subcategory - new get method with async-await syntax */
    getIngredientsByProductSubCategory(productSubCategoryId: number) {
        const url = this.main_service.getFrontendUrl() + '/productgroups/' + productSubCategoryId + '/ingredients';
        return this.http.get(url).toPromise();
    }

    /* Get the product from api by given product id */
    getProductByID(productId: number) {
        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        var delivType = (sessionStorage.getItem('pickup') === '1' ) ? 'pickup' : 'delivery';

        const url = this.main_service.getFrontendUrl() + '/products/' + productId + '/' + delivType + '/' + delivDate;
        return this.http.get(url).toPromise();
    }

    /* Get the ingredient from api by given ingredient id */
    getIngredientByID(ingredientId: number) {
        const url = this.main_service.getFrontendUrl() + '/ingredients/' + ingredientId;
        return this.http.get(url).toPromise();
    }

    // async test2(productSubCategoryId: number): Promise<any> {
    //     const url = this.main_service.getFrontendUrl() + '/productgroups/' + productSubCategoryId + '/ingredients';
    //     const response = await this.http.get<any>(url).toPromise();
    //     return response.json();
    // }

    async getAllBooks() {
        try {
            // GET a list of book IDs of the current user
            //   var bookIDs = await superagent.get('/user/books');
            const url = this.main_service.getFrontendUrl() + '/productgroups/' + 4 + '/ingredients';
            // wait for 3 seconds (just for the sake of this example)
            //   await delay();
            // GET information about each book
            return await this.http.get(url).toPromise();
        } catch (error) {
            /* If any of the awaited promises was rejected, this catch block would catch the rejection reason */
            return error;
        }
    }

    // gibt alle Artikel geordnet nach Kategorie zurück, die als Menüoption angeboten werden sollen
    getArticleForMenu() {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_menue_article';
        const delivType = this.getPickup();
        const delivDate = this.getSelectedDateTime();
        const url = this.main_service.getFrontendUrl() + '/products-for-menu' + '/' + delivType + '/' + delivDate;
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched all menu articles from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // gibt zu einer Adresse (PLZ + Ortsteil) den zugeordneten Mindestbestllwert zurück
    getMinVal(plz: string, district: string) {
        // const url = this.main_service.getFrontendUrl() + '?plz=' + plz + '&district=' + district;
        const url = this.main_service.getFrontendUrl() + '/get-min-order-value?plz=' + plz + '&district=' + district;

        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched min val for plz and district from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // validates couponcode
    checkCouponCode(code: string) {
        // const url = this.main_service.getFrontendUrl() + '?plz=' + plz + '&district=' + district;
        const url = this.main_service.getFrontendUrl() + '/check-coupon-code/' + code;
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`check coupon code from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // get all deilvery areas from backend (for single shop only)
    getDeliveryAreas() {
        // const url = this.main_service.getFrontendUrl() + '?plz=' + plz + '&district=' + district;
        const url = this.main_service.getFrontendUrl() + '/get-delivery-areas';

        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // Startseite/Aktion-Funktionen  - - - - - - - - - - - - - - - - - - - - - -

    // same wie get_article_list nur für alle aktuell relevanten Aktionsartikel
    getSpOfferArticle() {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_specal_offer_article';

        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        var delivType = (sessionStorage.getItem('pickup') === '1' ) ? 'pickup' : 'delivery';

        const url = this.main_service.getFrontendUrl() + '/special-offers' + '/' + delivType + '/' + delivDate;
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched all special offer articles from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // holt infos (Bild+Name) für den Header der Aktionsseite
    // getSpOfferHeader(){
    //     const url = this.main_service.getFrontendUrl() + '?data=' + 'get_specal_offer_header';
    //     return this.http.get(url)
    //         .pipe(
    //             retry(3),
    //             tap(data => this.log(`fetched all special offer articles from: ` + url)),
    //             catchError(this.handleError('getHeroes', []))
    //         );
    // }

    // gibt alle infos zu den Slidern auf der Startseite wieder
    getSliderInfo() {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_slider_info';
        const url = this.main_service.getFrontendUrl() + '/get-slider-info';
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched slider infos from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // Öffnungszeiten-Funktionen  - - - - - - - - - - - - - - - - - - - - - -

    // enthält öffnungszeiten + happy-hour bereiche für ein bestimmtes Datum (date im Format JJJ-MM-(T)T angeben)
    onGetOpeningHoursByDate(date: string, typeDeliv: any = false) {
        let deliveryType: any;
        if (!typeDeliv) {
            deliveryType = this.getPickup();
        } else {
            deliveryType = typeDeliv;
        }
        
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_opening_hours_date&date=' + date;
        const url = this.main_service.getFrontendUrl() + '/get-opening-hours-by-date/' + date + '?deliveryType=' + deliveryType;
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log('fetched opening hours for ' + date + ':' + url)),
                catchError(this.handleError('getHeroes', []))
            ).toPromise();
    }

    // returns the opening hours for today as json (plus happy hour, lunch etc)
    getOpeningHoursNow() {
        const deliveryType = this.getPickup();
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_opening_hours_today';
        const url = this.main_service.getFrontendUrl() + '/get-opening-hours-today' + '?deliveryType=' + deliveryType;
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched opening hours from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // returns array of opening hours in format used for frontend output in footer and wrapper
    getOpeningHours(opening_type: number, delivType: any = false) {
        let deliveryType: any = 'pickup';
        if (delivType) {
            deliveryType = delivType;
        } else {
            deliveryType = this.getPickup();
        }
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_opening_hours';
        const url = this.main_service.getFrontendUrl() + '/get-opening-hours/' + opening_type + '?deliveryType=' + deliveryType;
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched opening hours from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    getOpeningHoursArr(opening_type: string, selectedDate: string) {
        // const url = this.main_service.getFrontendUrl() + '?data=' + 'get_opening_hours';
        const url = this.main_service.getFrontendUrl() + '/open-times?date=' + selectedDate + '&delivery=' + opening_type;
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log('times for ' + selectedDate + ':' + url)),
                catchError(this.handleError('getHeroes', []))
            ).toPromise();
    }

    // Nutzer-Funktionen  - - - - - - - - - - - - - - - - - - - - - -

    // Updatet Nutzerdaten in der DB, anhand eines übergebenen JSON
    onEditUser(user_data: string) {
        const url = this.main_service.getFrontendUrl() + '?data=' + 'edit_user_data';
        this.http.post(url, user_data)
            .subscribe(
                (res: Response) => {
                    // console.log(res);
                },
                err => {
                    console.log("Error occured");
                    console.log(err);
                }
            );
    }

    // verändert die Mailadresse eines Users in der DB (nicht Firebase)
    changeMail(uid, new_mail, token) {
        const url = this.main_service.getFrontendUrl() + '?data=' + 'change_mail';
        let user_data = {
            'uid': uid,
            'new_mail': new_mail,
            'token': token,
        };
        this.http.post(url, JSON.stringify(user_data))
            .subscribe(
                (res: Response) => {
                    // console.log(res);
                },
                err => {
                    console.log("Error occured");
                    console.log(err);
                }
            );
    }

    // löscht User aus der DB (nicht firebase)
    deleteUser(token: string) {
        const url = this.main_service.getFrontendUrl() + '?data=' + 'delete_user&token=' + token;
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched slider infos from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    //erstellt Nutzer in der DB (nicht Firebase)
    onCreateUser(data: any) {
        const url = this.main_service.getFrontendUrl() + '?data=' + 'create_user';
        this.http.post(url, data)
            .subscribe(
                (res: Response) => {
                    // console.log(res);
                },
                err => {
                    console.log("Error occured");
                    console.log(err);
                }
            );
    }

    // erhält Adressdaten eines Nutzers anhand des Tokens
    fetchAdressData(token: string) {
        const url = this.main_service.getFrontendUrl() + '?data=' + 'get_adress&token=' + token;
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log('fetched Adress Data for ' + ':' + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // NO USAGE
    // gibt alle angelegten Zutatenkategorien zurück
    getIngCats() {
        const url = this.main_service.getFrontendUrl() + '?data=' + 'get_ing_categories';
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched ing categories from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // sendet die Bestellung ans BE zur validierung + verarbeitung
    onSendData(data: string) {
        // const url = this.main_service.getSubmitUrl() + '?data=' + 'send_offer';
        const url = this.main_service.getFrontendUrl() + '/orders';
        // log the post url
        // send the request
        this.http.post(url, JSON.stringify(data), httpOptions)
            .subscribe(
                (res: any) => {
                    if (res.data.mollieUrl) {
                        window.open(res.data.mollieUrl, '_blank', 'location=yes,scrollbars=yes,status=yes');
                        sessionStorage.setItem('paymentUrl', res.data.mollieUrl);
                        this.getMollieUrlSubject.next(res.data.mollieUrl);
                    }
                    this.orderResponseErrorSubject.next(res);
                    // request periodically
                    this.checkWinorderTrackingStatus = interval(5000)
                        .subscribe(() => {
                            this.onCheckWinorderTrackingStatus(res.data.orderID);
                        });
                },
                err => {
                    console.log('Error occured');
                    console.log(err);
                    this.orderResponseErrorSubject.next(err);
                }
            );
    }

    onCheckWinorderTrackingStatus(id: number) {
        const url = this.main_service.getFrontendUrl() + '/check-order-status/' + id;
        this.http.get(url)
            .pipe(
                retry(1),
                tap(data => this.log('fetched Adress Data for ' + ':' + url)),
                catchError(this.handleError('getHeroes', []))
            ).subscribe(
                (res: any) => {
                    // check winorder tracking status; 2 = order is beeing prepared
                    if (res.orderStatus === 2) {
                        this.checkWinorderTrackingStatusSubject.next(res.orderStatus);
                        this.checkWinorderTrackingStatus.unsubscribe();
                    } else {
                        this.checkWinorderTrackingStatusSubject.next(res.orderStatus);
                    }
                    if (res.url && res.url != sessionStorage.getItem('paymentUrl')) {
                        sessionStorage.setItem('paymentUrl', res.url);
                        this.getMollieUrlSubject.next(res.url);
                    }
                },
                err => {
                    console.log('Error occured: ' + err);
                }
            );
    }

    resetWinorderTrackingStatus(){
        this.checkWinorderTrackingStatusSubject.next(-1);
        if( this.checkWinorderTrackingStatus ){
            this.checkWinorderTrackingStatus.unsubscribe();
        }

    }

    getSettingsFromBackend() {
        const url = this.main_service.getFrontendUrl() + '/get-settings';
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched data from getSettingsFromBackend(): ` + url)),
                catchError(this.handleError('getSettingsFromBackend', []))
            );
    }

    getStoreData() {
        // if (!localStorage.getItem('storedata')) {
            const url = this.main_service.getFrontendUrl() + '/get-store-data';
            this.storeData = this.http.get(url)
                .pipe(
                    retry(3),
                    tap(data => {
                        // document.title = data['name'];
                        this.document.body.setAttribute('data-theme', data['theme'] );
                    }),
                    catchError(this.handleError('getStoreData', []))
                );
            this.storeData.subscribe(
                data => {
                    this.setStoreData(data);
                },
                err => console.error(err)
            );
            return this.storeData;
        // } else {
        //     // /* 1-st method */
        //     // this.storeData = of(JSON.parse(localStorage.getItem('storedata')));
        //     // this.document.body.setAttribute('data-theme', this.storeData['theme'] );
        //     // return this.storeData;

        //     /* 2-nd method */
        //     this.storeData = of(JSON.parse(localStorage.getItem('storedata')));
        //     let obs = new Observable((subscriber) => {
        //         setTimeout(()=>{
        //             subscriber.next(this.storeData);
        //             this.document.body.setAttribute('data-theme', this.storeData['theme'] );
        //             subscriber.complete();
        //         }, 50);
        //     });
        //     return obs;
        // }
    }

    setStoreData(data) {
        this.storeData = data;
        localStorage.setItem('storedata', JSON.stringify(this.storeData));
    }

    // sendet die Bestellung ans BE zur validierung + verarbeitung
    onAmazonPaySetOrderDetails(data: string) {
        const url = this.main_service.getFrontendUrl() + '/amazonPaySetOrderDetails';
        // send the request
        this.http.post(url, JSON.stringify(data), httpOptions)
            .subscribe(
                (res) => {
                    this.amazonPayGetOrderResponseSubject.next(res);
                },
                err => {
                    console.log('ERROR FROM BACKEND AMAZON PAY SET ORDER DETAILS' + err);
                }
            );
    }

    onAmazonPayConfirmOrder(data: string) {
        const url = this.main_service.getFrontendUrl() + '/amazonPayConfirmOrder';
        // send the request
        this.http.post(url, JSON.stringify(data), httpOptions)
            .subscribe(
                (res) => {
                    this.amazonPayConfirmOrderResponseSubject.next(res);
                },
                err => {
                    console.log('ERROR FROM BACKEND AMAZON PAY SET ORDER DETAILS' + err);
                }
            );
    }


    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // private functions
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            if (error.status === 0) { // Server nicht erreichbar
                this.router.navigate(['./server-offline']);
            } else {
                this.router.navigate(['./']);
            }

            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        // console.log('Action: ' + message);
    }

    checkMolliePayment(hash) {
        const url = this.main_service.getFrontendUrl() + '/mollie-payment-check?hash=' + hash;
        /*return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`fetched ing categories from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );*/
        return this.http.get(url)
            .toPromise()
            .then((data: any) => {

                const response = data;
                this.mollieresp = data;

            });
    }

    getCartItems(itemsArr) {
        return new Promise((resolve) => {
            const url = this.main_service.getFrontendUrl() + '/checkout';
            
            this.http.post(url, JSON.stringify(itemsArr), httpOptions).subscribe(
                (res) => {
                    resolve(res);
                },
                err => {
                    console.log('ERROR FROM BACKEND' + err);
                }
            );
            
        });
    }

    getPickup() {
        let typeDeliv = 'notSet';
        if (sessionStorage.getItem('pickup') ) {
            if (sessionStorage.getItem('pickup') == '1') {
                typeDeliv = 'pickup';
            } else if (sessionStorage.getItem('pickup') == '0') {
                typeDeliv = 'delivery';
            }
        }
        return typeDeliv;
    }

    getSelectedDateTime() {
        var loadedDeliveryDate = new Date(sessionStorage.getItem('deliveryDate'));
        var loadedDeliveryTime = sessionStorage.getItem('deliveryTime');
        loadedDeliveryDate.setHours(parseInt(loadedDeliveryTime.toString().substring(0, 2)));
        loadedDeliveryDate.setMinutes(parseInt(loadedDeliveryTime.toString().substring(3, 5)));
        var delivDate = loadedDeliveryDate.toString();
        return delivDate;
    }

    getCouponsArray() {
        const url = this.main_service.getFrontendUrl() + '/coupon-catalog';
        return this.http.get(url)
            .pipe(
                retry(3),
                tap(data => this.log(`check coupon code from: ` + url)),
                catchError(this.handleError('getHeroes', []))
            );
    }

    getCouponsHeaderImage() {
        const url = this.main_service.getImageUrl() + '/admin/header_coupons_page.png';
        return url;
    }

    getCouponsHeaderIcons() {
        const arr = [
            this.main_service.getImageUrl() + '/admin/header_coupons_page_icon_1.png', 
            this.main_service.getImageUrl() + '/admin/header_coupons_page_icon_2.png', 
            this.main_service.getImageUrl() + '/admin/header_coupons_page_icon_3.png',
            this.main_service.getImageUrl() + '/admin/header_coupons_page_icon_arrow.png'
        ];
        return arr;
    }

}
