<div class="row sortiment" *ngIf="ready">
    <ng-container *ngFor="let category of  data.section, let slideIndex = index">
    <div class="col-sm-6  mb-6" [ngClass]="data.section.length >= 8 ? 'col-md-3' : 'col-md-4'">
        <a class="mp-box-link d-block position-relative text-center" routerLink="{{category.url}}">

<picture><img src="{{image_url}}{{category.image.jpg[0].image}}" class="img-fluid" alt=""></picture>

         <!--  <picture>
                <source media="(min-width: 80em)" srcset="{{image_url}}{{data.image.jpg[0].image}}">
                <source  media="(min-width: 48em)" srcset="{{image_url}}{{data.image.jpg[0].image}}">
                <source  srcset="{{image_url}}{{data.image_mobile.jpg[0].image}}">
                <img  src="{{image_url}}{{data.image_mobile.jpg[0].image}}" alt="" class="img-fluid" />
            </picture>-->


            <h2>{{category.title}}</h2>
            <button class="mp-btn mp-btn-icon"><svg-icon src="assets/icons/icon_arrow_right.svg"></svg-icon></button>
        </a>
    </div>
    </ng-container>
</div>
