<div class="static-page-layout lunch-page">
    <div class="row">
        <div class="col-md-12">
            <div class="header-background-img header-wrapper headerBild d-flex justify-content-center align-items-center"
                 [style.backgroundImage]="'url(' + getHeaderBild() + ')'">
                <h1 class="static-page-title">Mittags-Angebot</h1>
            </div>
            <div class="col-md-12">
                <div class="center-content">
                    <h2 style="margin: 40px 0" class="text-center">Das Mittags-Angebot ist bestellbar von: <br>
                        <strong>Dienstag
                            - Freitag, 10:30 - 13:30 Uhr (außer an Feiertagen)</strong>

                    </h2>
                </div>
            </div>

            <div class="article-list-row row">
                <div class="col-12 col-md-6 col-xxl-4 col-centered article-list-wrap article-box ">

                    <div class="article-box-inner-wrap pizza">
                        <div class="article-list-content">
                            <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/11/Pizza"> jede Pizza
                                <br><strong>12,50 €</strong></a>
                            <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/96/Pizza"> jede Pizza
                                <br><strong>12,50 €</strong></a>
                        </div>
                    </div>

                    <div class="article-list-sub-content">
                        <h3 class="mp-font-large-yellow text-center article-name"><span class="text-uppercase">Meisterpizza</span>
                            30er</h3>
                        <p class="sub mp-font-regular-white">- Aus unserem Pizzasortiment -</p>
                    </div>
                    <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/11/Pizza"
                       class="mp-btn  bestellen-btn">lecker bestellen</a>
                    <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/96/Pizza"
                       class="mp-btn  bestellen-btn ">lecker bestellen</a>
                </div>

                <div class="col-12 col-md-6 col-xxl-4 col-centered article-list-wrap article-box">

                    <div class="article-box-inner-wrap burger">
                        <div class="article-list-content">
                            <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/13/Burger"> jeder Burger
                                <br><strong>12,50 €</strong></a>
                            <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/98/Burger"> jeder Burger
                                <br><strong>12,50 €</strong></a>
                        </div>

                    </div>

                    <div class="article-list-sub-content">
                        <h3 class="mp-font-large-yellow text-center article-name"><span
                                class="text-uppercase">Meisterburger</span> Classic + Fries nach Wahl</h3>
                        <p class="sub mp-font-regular-white">- Aus unserem Burgersortiment -</p>
                    </div>
                    <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/13/Burger"
                       class="mp-btn  bestellen-btn">lecker bestellen</a>
                    <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/98/Burger"
                       class="mp-btn  bestellen-btn">lecker bestellen</a>
                </div>

                <div class="col-12 col-md-6 col-xxl-4 col-centered article-list-wrap article-box">
                    <div class="article-box-inner-wrap salad">
                        <div class="article-list-content">
                            <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/14/Salat"> jeder Salat
                                <br><strong>12,50 €</strong></a>
                            <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/99/Salat"> jeder Salat
                                <br><strong>12,50 €</strong></a>
                        </div>
                    </div>

                    <div class="article-list-sub-content">
                        <h3 class="mp-font-large-yellow text-center article-name"><span class="text-uppercase">Meistersalat</span> +
                            Brotstange & Dip nach Wahl</h3>
                        <p class="sub mp-font-regular-white">- Aus unserem Salatsortiment -</p>
                    </div>
                    <a routerLink="/shop/bestellen/14/Salat" class="mp-btn bestellen-btn">lecker bestellen</a>
                </div>

                <!--Currywurst-->
                <div class="col-12 col-md-6 col-xxl-4 col-centered article-list-wrap article-box ">

                    <div class="article-box-inner-wrap currywurst">
                        <div class="article-list-content">
                            <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/145/Schnitzel & Co"> jede Currywurst
                                <br><strong>12,50 €</strong></a>
                            <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/146/Schnitzel & Co"> jede Currywurst
                                <br><strong>12,50 €</strong></a>
                        </div>
                    </div>

                    <div class="article-list-sub-content">
                        <h3 class="mp-font-large-yellow text-center article-name"><span class="text-uppercase">Riesen Currywurst</span>
                           </h3>
                        <p class="sub mp-font-regular-white">- Aus unserem Currywurstsortiment -</p>
                    </div>
                    <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/145/Schnitzel & Co"
                       class="mp-btn  bestellen-btn">lecker bestellen</a>
                    <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/146/Schnitzel & Co"
                       class="mp-btn  bestellen-btn ">lecker bestellen</a>
                </div>

                <!--Schnitzel-->
                <div class="col-12 col-md-6 col-xxl-4 col-centered article-list-wrap article-box ">

                    <div class="article-box-inner-wrap schnitzel">
                        <div class="article-list-content">
                            <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/145/Schnitzel & Co"> jeder Schnitzel
                                <br><strong>12,50 €</strong></a>
                            <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/146/Schnitzel & Co"> jeder Schnitzel
                                <br><strong>12,50 €</strong></a>
                        </div>
                    </div>

                    <div class="article-list-sub-content">
                        <h3 class="mp-font-large-yellow text-center article-name"><span class="text-uppercase">Meister-Schnitzel</span>
                            </h3>
                        <p class="sub mp-font-regular-white">- Aus unserem Schnitzelsortiment -</p>
                    </div>
                    <a *ngIf="storeData?.id === 2" routerLink="/shop/bestellen/145/Schnitzel & Co"
                       class="mp-btn  bestellen-btn">lecker bestellen</a>
                    <a *ngIf="storeData?.id === 7" routerLink="/shop/bestellen/146/Schnitzel & Co"
                       class="mp-btn  bestellen-btn ">lecker bestellen</a>
                </div>

            </div>
        </div>

    </div>
</div>
