<div class="static-page-layout">

    <div class="row">

        <div class="col-md-12">
            <div class="header-wrapper-small">
                <h1 class="static-page-title">AGB</h1>
            </div>
        </div>

    </div>

    <ng-container>
        <div class="row">

            <div class="col-md-12">

                <div *ngIf="storeData?.id === 5" class="center-content">
                    <h2 class="text-center">Allgemeine Geschäftsbedingungen von Pizza Blitz Vechta</h2>
                    <h3>Vertragsabschluss und Liefergebiet:</h3>
                    <ol type="a">
                        <li>Durch die Bestellung von Pizza bei unserem Lieferdienst erklärt der Kunde sein verbindliches Angebot zum
                            Abschluss eines Kaufvertrags.</li>
                        <li>Unsere Lieferungen beschränken sich auf das definierte Liefergebiet, das dem Kunden während des Bestellvorgangs
                            angezeigt wird. </li>
                    </ol>
                    <h3>Bestellungen:</h3>
                    <ol type="a">
                        <li>Bestellungen können telefonisch, online oder persönlich aufgegeben werden.</li>
                        <li>Änderungen oder Stornierungen von Bestellungen sind nur bis zu einem bestimmten Zeitpunkt vor der geplanten
                            Lieferung möglich, wie auf unserer Website angegeben.</li>
                    </ol>
                    <h3>Preise und Zahlungen:</h3>
                    <ol type="a">
                        <li>Die Preise für unsere Produkte sind auf unserer Website oder in unserer Speisekarte angegeben und verstehen sich
                            inklusive der gesetzlichen Mehrwertsteuer.</li>
                        <li>Zahlungen können in bar bei Lieferung oder online per Kreditkarte, PayPal oder anderen akzeptierten
                            Zahlungsmethoden erfolgen. </li>
                    </ol>
                    <h3>Lieferzeiten:</h3>
                    <ol type="a">
                        <li>Wir bemühen uns, die bestellten Speisen innerhalb der angegebenen Lieferzeiten zu liefern, jedoch können diese
                            je nach Verkehrsaufkommen oder anderen unvorhergesehenen Umständen variieren.</li>
                        <li>Bei Verzögerungen informieren wir den Kunden so früh wie möglich.</li>
                    </ol>
                    <h3>Qualität und Reklamationen:</h3>
                    <ol type="a">
                        <li>Wir garantieren die Qualität unserer Produkte und bemühen uns um höchste Kundenzufriedenheit.</li>
                        <li>Sollte ein Kunde mit der gelieferten Pizza nicht zufrieden sein, bitten wir um sofortige Benachrichtigung, um
                            eine angemessene Lösung zu finden.</li>
                    </ol>
                    <h3>Haftungsausschluss:</h3>
                    <ol type="a">
                        <li>Wir übernehmen keine Haftung für Schäden, die durch unsachgemäße Handhabung oder Lagerung der gelieferten
                            Produkte entstehen.</li>
                        <li>Wir haften nicht für Verzögerungen oder Ausfälle aufgrund höherer Gewalt oder unvorhergesehener Umstände.</li>
                    </ol>
                    <h3>Datenschutz:</h3>
                    <ol type="a">
                        <li>Wir respektieren die Privatsphäre unserer Kunden und behandeln persönliche Daten gemäß den geltenden
                            atenschutzgesetzen.</li>
                        <li>Kundendaten werden nur für die Abwicklung von Bestellungen und für Marketingzwecke verwendet, sofern der Kunde
                            dem ausdrücklich zugestimmt hat.</li>
                    </ol>
                    <h3>Anwendbares Recht und Gerichtsstand:</h3>
                    <ol type="a">
                        <li>Für alle Streitigkeiten im Zusammenhang mit diesen AGB gilt ausschließlich das deutsche Recht.</li>
                        <li>Gerichtsstand für alle Streitigkeiten ist Vechta. </li>
                    </ol>
                    <p>Mit der Bestellung bei unserem Pizza-Lieferdienst erklärt der Kunde sein Einverständnis mit diesen Allgemeinen
                        Geschäftsbedingungen.</p>
                    <br>
                    <p>
                        {{ storeData?.name }}<br>
                        {{ storeData?.street }} {{ storeData?.zip }} {{ storeData?.city }}<br>
                        Telefon: {{ storeData?.phone }}<br>
                        E-Mail: {{ storeData?.email }}
                    </p>

                </div>
                <div *ngIf="storeData?.id === 4 || storeData?.id === 6 || storeData?.id === 8" class="center-content">
                    <h2 class="text-center">Allgemeine Geschäftsbedingungen für Kunden</h2>
                    <p>
                        Die vorliegenden „Allgemeinen Geschäftsbedingungen für Kunden“ sind anwendbar auf die Beziehungen zwischen Takeaway.com
                        und den Kunden. Diese gelten nicht für Geschäfte (wie unten definiert, aber einschließlich Restaurants und Läden), die
                        unter die Bestimmungen des „Allgemeinen Geschäftsbedingungen für Restaurants“ verwiesen.
                    </p>                    
                    <ol class="changed-fs">
                        <li>
                            <h3>Begriffsbestimmungen:</h3>
                            <p>
                                Angebot: Die vom Geschäft angebotenen Produkte und Dienstleistungen, die durch den Kunden über die Plattform beim
                                Geschäft bestellt werden können.
                            </p>
                            <p>
                                Bestellung: Die Bestellung eines Angebots bei einem Geschäft über die Plattform durch den Kunden.
                            </p>
                            <p>
                                Kunde: Eine juristische oder natürliche Person (über 18 Jahre), die über die Plattform eine Bestellung beim Geschäft
                                abgibt.
                            </p>
                            <p>
                                Plattform: Die Webseite(n), Apps und Tools, die von Takeaway.com, mit Takeway.com verbundenen Unternehmen und/oder
                                Geschäftspartnern zur Verfügung gestellt werden, einschließlich der Geschäft, soweit eine solche vom Geschäft genutzt
                                wird.
                            </p>
                            <p>
                                Geschäft: Ein Ein auf der Plattform aufgelistetes Unternehmen, das Produkte und verwandte Artikel zur Verfügung stellt,
                                verkauft, zubereitet, verpackt, abholt und/oder anbietet, und das die Plattform zum Abschluss von Verträgen über
                                Angebote sowie zu deren Bezahlung nutzt.
                            </p>
                            <p>
                                Geschäftsinfos: Informationen über das Geschäft, unter anderem über den Händler und seine Kontaktmöglichkeiten, die
                                Produkte und Dienstleistungen, die im Rahmen des Angebots zur Verfügung gestellt werden (einschließlich z. B. Allergene,
                                Nährwertangaben und Inhaltsstoffe), Preise für jedes einzelne Produkt (einschließlich Mehrwertsteuer), Firmenlogo,
                                Grafiken, Liefergebiet (einschließlich Angabe der Postleitzahlen), Lieferkosten und Mindestbestellmengen sowie sonstige
                                Information zum Geschäft.
                            </p>
                            <p>
                                Artikel mit Altersbeschränkung: jedes Produkt, für das ein gesetzliches Mindestalter vorgeschrieben ist, einschließlich,
                                aber nicht beschränkt auf, z. B. alkoholische Produkte.
                            </p>
                            <p>
                                Service: Die Dienstleistungen, die dem Kunden von Takeaway.com über die Plattform angeboten werden, einschließlich der
                                Übermittlung von Bestellungen an das betreffende Geschäft. Die unter dem Vertrag vom Geschäft zu erbringenden Leistungen
                                sind hingegen nicht Teil des Services von Takeaway.com.
                            </p>
                            <p>
                                Takeaway.com: yd. yourdelivery GmbH, Registergericht: Amtsgericht Berlin-Charlottenburg, Handelsregisternummer HRB
                                118099, USt.-IdNr. DE266464862 (siehe auch Artikel 2 für weitere Informationen zu Takeaway.com) für sich selbst oder im
                                Namen einer natürlichen oder juristischen Person, die die Kontrolle ausübt, von der Takeaway.com Group B.V. kontrolliert
                                wird oder unter der gemeinsamen Kontrolle steht, direkt oder indirekt.
                            </p>
                            <p>
                                Trinkgeld: Ein vom Kunden freiwillig gezahlter Betrag, der für den Zusteller der Bestellung bestimmt ist.
                            </p>
                            <p>
                                Vertrag: Eine Vereinbarung zwischen dem Kunden und dem Geschäft bezüglich einer Bestellung inklusive Lieferung oder
                                Abholung der Bestellung. Parteien des Vertrags sind der Kunde und das Geschäft, nicht jedoch Takeaway.com.
                            </p>
                        </li>
                        <li>
                            <h3>Identität von Takeaway.com</h3>
                            <p>
                                Takeaway.com ist in Deutschland unter der Bezeichnung „Lieferando.de“ tätig.
                            </p>
                            <p>
                                yd. yourdelivery GmbH<br>
                                Cuvrystr. 50, 52, 54 / Schlesische Str. 34,<br>
                                10997 Berlin
                            </p>
                            <p>
                                E-Mail: info@lieferando.de
                            </p>
                            <p>
                                Tel: 030 837 96 000 (erreichbar montags bis sonntags 08.00 bis 01.00 Uhr)<br>
                                Fax: 0800 202 07 702
                            </p>
                        </li>
                        <li>
                            <h3>Anwendbarkeit</h3>
                            <p>
                                Die vorliegenden Allgemeinen Geschäftsbedingungen für Kunden sind nur auf den Service anwendbar.
                            </p>
                            <p>
                                Durch die Aufgabe einer Bestellung schließt der Kunde einen Vertrag mit dem Geschäft über die Lieferung des vom Kunden
                                ausgewählten Angebots.
                            </p>
                            <p>
                                Außer in den Fällen, in denen Takeaway.com in den Geschäftsinfos auf der Plattform ausdrücklich als Verkäufer angegeben
                                ist (auch bei Lieferando Express), Takeaway.com ist nicht für das Angebot und/oder den Vertrag zwischen dem Kunden und
                                dem Geschäft verantwortlich. Gegebenenfalls gelten die Allgemeinen Geschäftsbedingungen des Geschäfts zusätzlich für das
                                Angebot und den Vertrag.
                            </p>
                        </li>
                        <li>
                            <h3>Angebot</h3>
                            <p>
                                Takeaway.com veröffentlicht das Angebot im Namen des betreffenden Geschäfts, ausgehend von den bereitgestellten
                                Geschäftsinfos, veröffentlicht. Takeaway.com überprüft nicht die Richtigkeit oder Vollständigkeit der
                                Restaurantinformationen und ist nicht verantwortlich für die Durchführung des Vertrags.
                            </p>
                            <p>
                                Das Restaurant verwendet gegebenenfalls Zutaten und Zusatzstoffe für Speisen und Getränke, die Allergien und
                                Unverträglichkeiten auslösen können. Wenn Sie allergisch auf Lebensmittel reagieren, raten wir Ihnen, sich telefonisch
                                mit dem Geschäft in Verbindung zu setzen, um sich über verwendete Allergene zu informieren, bevor Sie eine Bestellung
                                aufgeben.
                            </p>
                        </li>
                        <li>
                            <h3>Vertrag zwischen dem Geschäft und Kunden</h3>
                            <p>
                                Der Vertrag zwischen dem Geschäft und dem Kunden kommt wirksam zustande, sobald der Kunde die Bestellung aufgibt am Ende
                                des Bestellvorgangs auf der Plattform und die Schaltfläche „Zahlungspflichtig Bestellen“ anklickt.
                            </p>
                            <p>
                                Nach Eingang der Bestellung wird Takeaway.com die Bestellung dem Kunden elektronisch bestätigen.
                            </p>
                            <p>
                                Wenn der Kunde sich noch nicht eingeloggt oder ein Konto erstellt hat, hat er die Möglichkeit, seine Bestellung als Gast
                                fortzusetzen. Wenn der Kunde die Bestellung als Gast abschließt, erklärt er sich damit einverstanden, dass Lieferando
                                ein temporäres Lieferando-Gastkonto einrichtet, das, sofern in diesen Allgemeinen Geschäftsbedingungen nicht anders
                                angegeben, nur (a) von dem Gerät aus zugänglich ist, das der Kunde für die Bestellung verwendet, und (b) für einen
                                Zeitraum von bis zu zwölf Monaten nach dem Datum der Bestellung. Der Zugriff des Kunden auf sein temporäres Gastkonto
                                läuft am "Ablaufdatum" ab, wobei der frühere der folgenden Zeitpunkte gilt: (i) das Datum, das sechs Monate nach dem
                                Datum liegt, an dem der Kunde das letzte Mal auf das temporäre Gastkonto zugegriffen hat; (ii) das Datum, das zwölf
                                Monate nach dem Datum liegt, an dem das temporäre Gastkonto erstellt wurde; oder (iii) das Datum, an dem sich der Kunde
                                von seinem temporären Gastkonto abmeldet oder den Cache auf dem Gerät löscht, das der Kunde bei der Erstellung des
                                temporären Gastkontos verwendet hat.
                            </p>
                            <p>
                                Das Geschäft ist berechtigt, die Bestellung zu stornieren, wenn das Angebot nicht mehr verfügbar ist, wenn der Kunde
                                eine falsche oder nicht funktionierende Telefonnummer oder andere Kontaktinformationen angegeben hat oder wenn ein Fall
                                höherer Gewalt vorliegt.
                            </p>
                            <p>
                                Der Vertrag kann vom Geschäft nur dann ausgeführt werden, wenn der Kunde bei der Bestellung korrekte und vollständige
                                Kontakt- und Adressinformationen zur Verfügung stellt. Der Kunde ist verpflichtet, unverzüglich alle Ungenauigkeiten der
                                Informationen (einschließlich der Zahlungsdaten) zu melden, die an Takeaway.com oder das Geschäft übermittelt oder
                                weitergegeben wurden.
                            </p>
                            <p>
                                Der Kunde muss telefonisch oder per E-Mail (wie bei der Bestellung angegeben) für das Geschäft und Takeaway.com
                                erreichbar sein, um Informationen betreffend den Status seiner Bestellung erhalten zu können.
                            </p>
                            <p>
                                Wenn das Geschäft, bei dem der Kunde seine Bestellung aufgibt, einen Lieferservice von Takeaway.com in Anspruch nimmt,
                                kann Takeaway.com dem Kunden eine Liefergebühr und/oder eine Servicegebühr berechnen. Die für die Bestellung anfallenden
                                Liefer- und Servicegebühren können von einer Reihe von Faktoren abhängen, wie z.B. dem Standort, dem ausgewählten
                                Geschäft und dem Wert der in der Bestellung enthaltenen Artikel, aber diese Kosten werden immer auf der Plattform
                                angezeigt, bevor ein Kunde eine Bestellung aufgibt. Eine Quittung für diese Liefergebühren und Servicegebühren kann bei
                                Takeaway.com angefordert werden.
                            </p>
                            <p>
                                Entscheidet sich der Kunde dafür, die Bestellung liefern zu lassen, muss der Kunde an der vom Kunden angegebenen
                                Lieferadresse anwesend sein, um die bestellten Artikel in Empfang zu nehmen. Insofern der Kunde nicht an der
                                Lieferadresse anwesend ist, wenn die Bestellung geliefert wird, und die Bestellung von Takeaway.com geliefert wird (und
                                nicht vom Geschäft selbst), wird Takeaway.com angemessene Anstrengungen unternehmen, um den Kunden zu kontaktieren, um
                                zu bestimmen, wo die Bestellung hinterlassen werden soll. Wenn Takeaway.com nicht in der Lage ist, den Kunden zu
                                kontaktieren, kann Takeaway.com die Bestellung an einem angemessenen Ort außerhalb, in der Nähe der Lieferadresse,
                                hinterlegen. Takeaway.com übernimmt keine Verantwortung für die Bestellung (einschließlich der Qualität oder Sicherheit
                                des Inhalts der Bestellung, wenn der Kunde sie vorfindet), nachdem die Bestellung zugestellt wurde. Bitte beachten Sie,
                                dass das Geschäft, wenn es die Bestellung selbst ausgeliefert und nicht die Lieferdienste von Takeaway.com in Anspruch
                                nimmt, entscheidet, ob es die Bestellung außerhalb der Lieferadresse hinterlässt, wenn der Kunde nicht anwesend ist.
                                Falls der Kunde die Bestellung abholen möchte, sollte er zum gewählten Zeitpunkt am Abholungsort des Geschäfts anwesend
                                sein, wie in der Bestätigungs-E-Mail, SMS oder auf der Plattform angegeben.
                            </p>
                            <p>
                                Bei der Bestellung sowie bei der Auslieferung oder Abholung von Bestellungen mit Artikeln mit Altersbeschränkung: werden
                                Takeaway.com und das Geschäft den Kunden gemäß den geltenden Gesetzen und Vorschriften auffordern, sich auszuweisen.
                                Kann sich der Kunde nicht hinreichend ausweisen oder erfült er das Mindestalter nicht, werden die Artikel mit
                                Altersbeschränkung der Bestellung nicht ausgeliefert und es steht Takeaway.com und dem Geschäft frei, die Bestellung
                                auch insgesamt abzulehnen. Werden die Artikel mit Altersbeschränkung gemäß diesem Absatz nicht geliefert, kann dem
                                Kunden eine Stornogebühr in Rechnung gestellt werden, die mindestens den Wert der Artikel mit Altersbeschränkung der
                                jeweligen Bestellung beträgt.
                            </p>
                            <p>
                                Takeaway.com übernimmt keine Haftung für die Ausführung des Vertrages.
                            </p>
                            <p>
                                Nach der Bestellung kann der Kunde nach eigenem Ermessen entscheiden, einem Zusteller über die verfügbaren
                                Online-Zahlungsmethoden ein Trinkgeld zu geben.
                            </p>
                            <p>
                                Das Trinkgeld ist für die Zusteller bestimmt und kann nicht als Zahlung für Dienstleistungen von Takeaway.com angesehen
                                werden. Takeaway.com tritt dabei nur als Treuhänder und Überweiser der Trinkgeldbeträge auf.
                            </p>
                            <p>
                                Takeaway.com überweist das Trinkgeld an die Zusteller, falls diese direkt über Takeaway.com beauftragt werden. Falls ein
                                Zusteller nicht über Takeaway.com, sondern direkt durch das Geschäft beauftragt wird, überweist Takeaway.com das
                                Trinkgeld an das Geschäft und verpflichtet das Geschäft, das Trinkgeld an den Zusteller auszuzahlen. Takeaway.com kann
                                keine Garantie oder Verantwortung für die Übertragung des Trinkgeldes vom Restaurant an den Zusteller übernehmen.
                            </p>
                            <p>
                                Wenn der Kunde eine Bestätigung über die Platzierung des Trinkgeldes erhalten hat, kann das Trinkgeld nicht mehr
                                zurückerstattet oder zurückgegeben werden.
                            </p>
                        </li>
                        <li>
                            <h3>Ablehnung von Bestellungen</h3>
                            <p>
                                Unbeschadet der Rechte des Kunden aus § 7(2) (die weiterhin gelten) hängt das Recht des Kunden, den Vertrag aufzulösen,
                                von seinem Angebot ab. Insbesondere, wenn die im Angebot enthaltenen Waren verderblich sind oder anderweitig einen der
                                Punkte in Artikel 7(1) erfüllen, ist es der Kunde nicht gestattet, den Vertrag aufzulösen. Bestellungen können
                                Takeaway.com gegenüber nicht vom Kunde aufgelöst werden. Eine Stornierung der Bestellung gegenüber dem Geschäft ist für
                                den Kunden nur dann möglich, wenn das Geschäft ausdrücklich angibt, dass eine Stornierung der Bestellung durch den
                                Kunden möglich ist.
                            </p>
                            <p>
                                Das Geschäft ist berechtigt, die Bestellung zu stornieren, z.B. wenn das Angebot nicht mehr verfügbar ist, wenn der
                                Kunde eine falsche oder nicht funktionierende Telefonnummer oder andere Kontaktinformationen angegeben hat oder wenn
                                höhere Gewalt vorliegt.Takeaway.com ist berechtigt, alle (künftigen) Bestellungen von der Kunde abzulehnen, sollten
                                entsprechende Gründe vorliegen.
                            </p>
                            <p>
                                Wenn der Kunde eine falsche Bestellung (z.B. indem er falsche Kontaktinformationen angibt, indem er nicht bezahlt oder
                                nicht am Lieferungs- oder Abholungsort anwesend ist, um den Auftrag zu erhalten) aufgibt oder anderweitig seinen
                                Verpflichtungen gemäß dem Vertrag nicht nachkommt, ist Takeaway.com berechtigt, zukünftige Bestellungen von diesem
                                Kunden abzulehnen.
                            </p>
                            <p>
                                Takeaway.com ist berechtigt, Bestellungen abzulehnen und Verträge im Namen des Geschäfts aufzulösen, wenn es angemessene
                                Zweifel bezüglich der Richtigkeit oder Echtheit der Bestellung oder der Kontaktinformationen gibt oder falls das
                                Geschäft keinen Vertrag mit der Kunde schließen möchte. Falls der Kunde Bestellungen aufgibt, die nachweislich falsch
                                oder betrügerisch sind, ist Takeaway.com berechtigt, bei der Polizei Anzeige zu erstatten. Wenn der Kunde Bestellungen
                                aufgibt, die falsch oder betrügerisch erscheinen, kann Takeaway.com dies der Polizei melden.
                            </p>
                        </li>
                        <li>
                            <h3>Widerrufsrecht</h3>
                            <ul>
                                <p>Der Kunde kann die Bestellung nicht gegenüber dem Geschäft widerrufen, soweit es sich um die Lieferung von Waren handelt,</p>
                                <li>
                                    wenn die Waren nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den
                                    Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind (§
                                    312g Abs. 2 Nr. 1 BGB);
                                </li>
                                <li>
                                    wenn die Waren schnell verderben können oder deren Verfallsdatum schnell überschritten würde (§ 312g Abs. 2 Nr. 2 BGB);
                                </li>
                                <li>
                                    die aus Gründen des Gesundheitsschutzes oder der Hygiene die Waren nicht zur Rückgabe geeignet sind, wenn ihre
                                    Versiegelung nach der Lieferung entfernt wurde (§ 312g Abs. 2 Nr. 3 BGB);
                                </li>
                                <li>
                                    wenn die Waren nach der Lieferung aufgrund ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt wurden (§ 312g
                                    Abs. 2 Nr. 4 BGB).
                                </li>
                            </ul>
                            <p>
                                Für den Teil der Bestellung, der nicht unter die oben genannten Ausschlusstatbestände fällt, steht dem Kunden, soweit
                                der Kunde Verbraucher im Sinne von § 13 BGB ist, ein Widerrufsrecht zu. „Verbraucher“ ist jede natürliche Person, die
                                ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen
                                Tätigkeit zugerechnet werden können.
                            </p>
                            <h4>Widerrufsbelehrung</h4>
                            <h5>Widerrufsrecht</h5>
                            <p>
                                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen den Vertrag mit dem Geschäft zu widerrufen. Die
                                Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der
                                Beförderer ist, die Waren in Besitz genommen haben bzw. hat.
                            </p>
                            <p>
                                Um Ihr Widerrufsrecht auszuüben, müssen Sie Takeaway.com (yd. yourdelivery GmbH, Cuvrystr. 50, 52, 54 / Schlesische Str.
                                34, 10997 Berlin, info@lieferando.de, Fax: 0800 202 07 702) mittels einer eindeutigen Erklärung (z. B. ein mit der Post
                                versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, den Vertrag mit dem Geschäft zu widerrufen, informieren.
                                Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
                            </p>
                            <p>
                                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf
                                der Widerrufsfrist absenden.
                            </p>
                            <h5>Folgen des Widerrufs</h5>
                            <p>
                                Wenn Sie den Vertrag mit dem Geschäft widerrufen, hat das Geschäft Ihnen alle Zahlungen, die es von Ihnen erhalten hat,
                                einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art
                                der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens
                                binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf des Vertrags bei uns
                                eingegangen ist. Für diese Rückzahlung verwendet das Geschäft dasselbe Zahlungsmittel, das Sie bei der ursprünglichen
                                Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden
                                Ihnen wegen dieser Rückzahlung Entgelte berechnet.
                            </p>
                            <p>
                                Das Geschäft kann die Rückzahlung verweigern, bis es die Waren wieder zurückerhalten hat oder bis Sie den Nachweis
                                erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
                            </p>
                            <p>
                                Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
                            </p>
                            <p>
                                Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der
                                Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist
                            </p>
                            <p>
                                An yd. yourdelivery GmbH, Cuvrystr. 50, 52, 54 / Schlesische Str. 34, 10997 Berlin, info@lieferando.de, Fax: 0800 202 07 702
                            </p>
                            <p>
                                Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die
                                Erbringung der folgenden Dienstleistung (*)
                            </p>
                        </li>
                        <li>
                            <h3>Zahlung</h3>
                            <p>
                                Zum Zeitpunkt des Vertragsabschlusses entsprechend den Bestimmungen aus vorliegenden Allgemeinen Geschäftsbedingungen
                                für Kunden entsteht auf Seiten des Kunden eine Leistungsverpflichtung gegenüber dem Geschäft. Der Kunde kann diese
                                Zahlungsverpflichtung durch Nutzung einer Online-Zahlungsmethode über die Plattform oder durch Bezahlung des Geschäfts
                                an der Tür oder am Abholort erfüllen.
                            </p>
                            <p>
                                Nach Maßgabe der Bestimmungen aus dieser Allgemeinen Geschäftsbedingungen für Kunden ist die (teilweise) Rückerstattung
                                einer Online-Zahlung nur dann möglich, wenn die Bestellung nicht (vollständig) geliefert werden kann. Die Rückerstattung
                                erfolgt immer auf das Konto, von dem aus die Zahlung vorgenommen wurde. Abhängig von der vom Kunden verwendeten
                                Zahlungsmethode dauert die Bearbeitung der Rückerstattung maximal 10 Werktage.
                            </p>
                            <p>
                                Das Geschäft hat Takeaway.com bevollmächtigt, die Online-Zahlung des Kunden im Namen des Geschäfts anzunehmen.
                            </p>
                        </li>
                        <li>
                            <h3>Beilegung von Beschwerden</h3>
                            <p>
                                Kundenseitige Beschwerden betreffend des Angebotes, der Bestellung oder der Ausführung des Vertrags mit dem Geschäft
                                müssen gegenüber dem Geschäft geltend gemacht werden. Die alleinige Verantwortung für das Angebot des Geschäfts und die
                                Erfüllung der Vereinbarung liegt beim Geschäft. Im Falle von solchen Beschwerden kann Takeaway.com nur eine schlichtende
                                Rolle einnehmen.
                            </p>
                            <p>
                                Falls der Kunde eine Beschwerde bezüglich der Services hat, muss diese Beschwerde dem Kundendienst von Takeaway.com
                                mittels des Kontaktformulars, per E-Mail oder auf dem Postwege, an die in Artikel 2 der vorliegenden Allgemeinen
                                Geschäftsbedingungen angegebene Kontaktadresse, gemeldet werden.
                            </p>
                            <p>
                                Sobald Takeaway.com die Beschwerde erhalten hat, wird Takeaway.com so schnell wie möglich, spätestens jedoch innerhalb
                                einer Woche, Empfangsbestätigung antworten. Takeaway.com ist bemüht, die Beschwerde so bald wie möglich zu bearbeiten
                                spätestens jedoch innerhalb von zwei Wochen.
                            </p>
                            <p>
                                Beschwerden, wie sie in den Abschnitten 1 und 2 dieses Artikels beschrieben sind, sollen dem Geschäft (Abschnitt 1) oder
                                Takeaway.com (Abschnitt 2) innerhalb einer angemessenen Frist, nachdem der Kunde die Mängel festgestellt hat, gemeldet
                                und vollständig und deutlich beschrieben werden.
                            </p>
                            <p>
                                Die Europäische Kommission stellt eine Plattform für die Online-Streitbeilegung bereit (OS-Plattform). Diese Plattform
                                ist zu finden unter http://ec.europa.eu/odr . Takeaway.com schließt ausdrücklich eine alternative Streitbeilegung gemäß
                                Richtlinie 2013/11/EU aus. Die E-Mail-Adresse von Takeaway.com lautet info@lieferando.de .
                            </p>
                            <p>
                                Takeaway.com ist nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
                                teilzunehmen.
                            </p>
                        </li>
                        <li>
                            <h3>Newsletter</h3>
                            <p>
                                Bei seiner Bestellung kann der Kunde einen Newsletter von Takeaway.com abonnieren. Der Kunde kann diesen Newsletter
                                jederzeit auf der Webseite lieferando.de/newsletter abbestellen oder zum Abbestellen Takeaway.com (siehe Artikel 2
                                dieser Allgemeinen Geschäftsbedingungen) kontaktieren. Hierfür entstehen keine anderen als die Übermittlungskosten nach
                                den Basistarifen.
                            </p>
                        </li>
                        <li>
                            <h3>Bewertungen der Geschäfte</h3>
                            <p>
                                Takeaway.com bietet den Kunden die Möglichkeit, die Leistung des Geschäfts zu bewerten. Die eingestellten Bewertungen
                                des Kunden dürfen keinen rechtsradikalen, sexistischen, beleidigenden, verleumderischen oder sonst rechtswidrigen Inhalt
                                haben und auch sonst keine Rechte Dritter (einschließlich gewerblicher Schutzrechte) verletzen.
                            </p>
                            <p>
                                Takeaway.com kann Bewertungen auf der Plattform veröffentlichen und von der Plattform entfernen, wenn die Bewertungen
                                gegen die vorgenannten Kriterien verstoßen.
                            </p>
                        </li>
                        <li>
                            <h3>Verarbeitung von personenbezogenen Daten</h3>
                            <p>
                                Takeaway.com verarbeitet personenbezogene Daten in Bezug auf den Kunden. Die Verarbeitung personenbezogener Daten
                                unterliegt den Bestimmungen der Datenschutzerklärung.
                            </p>
                        </li>
                        <li>
                            <h3>Haftung Takeaway.com</h3>
                            <p>
                                Takeaway.com haftet nicht für das Angebot, die Richtigkeit und Vollständigkeit der Geschäft, die Durchführung des
                                Vertrags und/oder die Erreichbarkeit der Plattform.
                            </p>
                            <h5>Im Übrigen haftet Takeaway.com wie folgt:</h5>
                            <p>
                                Takeaway.com haftet jeweils nach Maßgabe der gesetzlichen Vorschriften in folgenden Fällen auf Aufwendungs- und
                                Schadensersatz (im Folgenden in dieser Ziffer 5: „Schadensersatz“): bei einer Haftung nach dem Produkthaftungsgesetz, in
                                Fällen des Vorsatzes oder bei arglistiger Täuschung, in Fällen grober Fahrlässigkeit, für die Verletzung des Lebens, des
                                Körpers oder der Gesundheit, bei Übernahme einer Garantie durch Takeaway.com sowie in allen anderen Fällen gesetzlich
                                zwingender Haftung.
                            </p>
                            <p>
                                Takeaway.com haftet außerdem bei der schuldhaften Verletzung sog. Kardinalpflichten auf Schadensersatz nach Maßgabe der
                                gesetzlichen Vorschriften. Kardinalpflichten in diesem Sinn sind alle Pflichten, deren Verletzung die Erreichung des
                                Vertragszwecks gefährdet sowie alle Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt
                                erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertrauen darf. Soweit jedoch die Verletzung einer
                                Kardinalpflicht nur leicht fahrlässig geschah und nicht zu einer Verletzung von Leben, Körper oder Gesundheit führte,
                                sind Ansprüche auf Schadensersatz der Höhe nach auf den typischen vorhersehbaren Schaden beschränkt.
                            </p>
                            <p>
                                Im Übrigen sind Ansprüche des Kunden auf Schadensersatz gegen Takeaway.com – gleich aus welchem Rechtsgrund,
                                insbesondere wegen Sachmangel, Rechtsmangel und/oder Verletzung von anderen Pflichten aus dem Schuldverhältnis oder aus
                                einem vorvertraglichen Schuldverhältnis (z.B. i.S.v. § 311 Abs. 2 BGB) durch Takeaway.com, deren gesetzliche Vertreter,
                                Mitarbeiter oder Erfüllungsgehilfen, aus § 311 a BGB oder aus unerlaubter Handlung – ausgeschlossen.
                            </p>
                            <p>
                                Soweit nach den vorstehenden Regelungen die Haftung von Takeaway.com eingeschränkt oder ausgeschlossen ist, gilt das
                                auch für die persönliche Haftung der gesetzlichen Vertreter, Mitarbeiter und Erfüllungsgehilfen von Takeaway.com.
                            </p>
                            <p>
                                Eine Änderung der Beweislast zum Nachteil des Kunden ist mit den vorstehenden Regelungen nicht verbunden.
                            </p>
                            <p>
                                Ein Ausschluss oder eine Beschränkung von Ansprüchen des Kunden auf Verzugszinsen oder auf die Pauschale nach § 288 Abs.
                                5 BGB oder auf Ersatz des Schadens, der in Kosten der Rechtsverfolgung begründet ist, ist mit den vorstehenden
                                Regelungen nicht verbunden.
                            </p>
                            <p>
                                Ansprüche des Kunden auf Schadensersatz verjähren innerhalb der gesetzlichen Verjährungsfristen ab gesetzlichem
                                Fristbeginn.
                            </p>
                        </li>
                        <li>
                            <h3>Sonstiges</h3>
                            <p>
                                Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.
                            </p>
                            <p>
                                Vereinbarung zwischen dem Kunden und Takeaway.com über die Serviceleistungen oder den Vertrag zwischen dem Geschäft und
                                dem Kunden.
                            </p>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </ng-container>
</div>
