<div class="row mb-7" *ngIf="ready">
    <div class="col-xl-6" *ngIf="dataHasImg">
        <div class="category-img">
            <picture>
                <source media="(min-width: 80em)" srcset="{{image_url}}{{data.image.jpg[0].image}}">
                <source  media="(min-width: 48em)" srcset="{{image_url}}{{data.image.jpg[0].image}}">
                <source  srcset="{{image_url}}{{data.image_mobile.jpg[0].image}}">
                <img  src="{{image_url}}{{data.image_mobile.jpg[0].image}}" alt="" class="img-fluid" />
            </picture>
            <div class="category-title">
                <p class="category-title-inner">
                    <!-- Aktion -->
                </p>
            </div>
        </div>
    </div>
    <div class="col-xl-6 d-flex flex-column justify-content-center text-center mx-auto">
        <h2>{{data.headline}}</h2>
        <div [innerHTML]="data.text|safeHtml"></div>
    </div>
</div>