import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MainService } from '../../../services/main.service';
import { CartService } from '../../../services/cart.service';
import { UserService } from '../../../services/user.service';
import { FrontendService } from '../../../services/frontend.service';
import { SettingsService } from '../../../services/settings.service';
import { Router } from '@angular/router';
import { passBoolean } from 'protractor/built/util';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

    on_pickup: boolean;
    current_page_id = -1;

    total_amount: number;
    total_price: number;
    mobile: boolean;
    show_navigation: boolean;

    minOrderValue: number;
    masterCategories: any = [];

    showLunch: any = false;
    showOffer: any = false;
    logo: any;

    @Input() is_open;
    @Output() toggle_is_open_emitter = new EventEmitter();

    // private stuff - - - - - - - - - - - - - - - -
    private subscriptions: Subscription[] = [];


    // für scroll
    storeData: any;

    showDownButton = false;
    showUpDownButton = false;

    // constructor   - - - - - - - - - - - - - - - -
    constructor(
        private cart_service: CartService,
        private main_service: MainService,
        private user_service: UserService,
        private frontend_service: FrontendService,
        private router: Router,
        private pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any,
        private settings: SettingsService
    ) {
    }


    ngOnInit() {

        this.settings.get('showLunch').then(setting => {
            this.showLunch = setting;
        });

        this.settings.get('showOffer').then(setting => {
            this.showOffer = setting;
        });

        this.settings.get('theme.logo').then(setting => {
            this.logo = setting;
            // console.log("LOGO",this.logo);
        });

        this.show_navigation = ((sessionStorage.getItem('pickup') === '1') || !!(sessionStorage.getItem('tempUser')));
        // console.log("SHOW NAVIGATION",this.show_navigation);

        this.frontend_service.getMasterCategories().subscribe(
            (data: any) => {
                this.masterCategories = data;
                // console.warn(this.masterCategories);
            });


        this.subscriptions.push(this.main_service.current_page_id_observable.subscribe(
            (data: number) => {
                this.current_page_id = data;
            }
        ));

        this.subscriptions.push(this.cart_service.article_amount_observable.subscribe(
            data => {
                this.total_amount = data;
            }
        ));

        this.storeData = JSON.parse(localStorage.getItem('storedata'));

    }

    ngOnDestroy() {
        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    }

    onToggleOpen(new_page_id: number) {
        this.main_service.setCurrentPageID(new_page_id);
        this.current_page_id = new_page_id;
        this.toggle_is_open_emitter.emit();
    }

    toCart() {

        this.router.navigate(['shop/bestellen/warenkorb']);
    }



    toBottom() {
        document.documentElement.scrollBy({ top: 200, left: 300, behavior: "smooth" });
    }

    schowButtons() {
        // für Buttons Up und Down
        let mql1 = window.matchMedia('(max-width: 1279px)').matches;
        let mql2 = window.matchMedia('(min-width: 1280px)').matches;
        let hoehe1 = 1056;
        let hoehe2 = 1200;


        if ((!mql1 && (window.innerHeight < hoehe1))) {
            this.showDownButton = true;
            this.showUpDownButton = true;
        }
    }

}

