
export class User {
    fam_name: string;
    fir_name: string;
    adress: Adress;
    phone: string;
    mail: string;
    title: string;

    use_cookie: boolean;
    has_acc: boolean;
    no_marketing: boolean;


    constructor() {
        this.fam_name = '';
        this.fir_name = '';
        this.phone = '';
        this.mail = '';
        this.title = '';
        this.no_marketing = true;
        this.setAdress('', '', '', '', '');
    }

    setAdress(plz: string, city: string, district: string, street: string, street_number: string) {
        this.adress = new Adress(plz, city, district, street, street_number);
    }

    setStates(fam_name: string, first_name: string, phone: string, mail: string) {
        this.fam_name = fam_name;
        this.fir_name = first_name;
        this.phone = phone;
        this.mail = mail;
    }

}

export class Adress {
    plz: string;
    city: string;
    district: string;
    street: string;
    street_number: string;
    firma: string;
    department: string;
    etage: string;
    comment: string;

    constructor(plz: string, city: string, district: string, street: string, street_number: string) {
        this.plz = plz;
        this.city = city;
        this.district = district;
        this.street = street;
        this.street_number = street_number;
        this.firma = '';
        this.department = '';
        this.etage = '';
        this.comment = '';
    }
}
