import { Component, OnInit } from '@angular/core';
import { FrontendService } from '../../../services/frontend.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-coupons-page',
  templateUrl: './coupons-page.component.html',
  styleUrls: ['./coupons-page.component.scss']
})
export class CouponsPageComponent implements OnInit {

  couponsArray: any;
  noCoupons = false;
  headerImageLink = '';
  headerIconOne = '';
  headerIconTwo = '';
  headerIconThree = '';
  headerIconArrow = '';




  constructor(
    private router: Router,
    private frontend_service: FrontendService
  ) { }

  ngOnInit() {
    this.frontend_service.getCouponsArray().subscribe(data => {
        if (typeof data[0] == 'undefined') {
          this.noCoupons = true;
        }
        this.couponsArray = data;
    });
    // this.headerImageLink = this.frontend_service.getCouponsHeaderImage();
    // let iconArr = this.frontend_service.getCouponsHeaderIcons();
    // this.headerIconOne = iconArr[0];
    // this.headerIconTwo = iconArr[1];
    // this.headerIconThree = iconArr[2];
    // this.headerIconArrow = iconArr[3];
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    document.getElementById(val).style.display = 'none';
    document.getElementById(val + '_copied').style.display = 'block';
    setTimeout(() => {
        document.getElementById(val).style.display = 'block';
        document.getElementById(val + '_copied').style.display = 'none';
  }, 2000);
  }

}
